import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import "../../Api/apiUrls";
import { getAdminVenueProfile, updateUser } from "../../Api/apiUrls";

function ViewProfile() {
  const [userData, setUserData] = useState("");
  const [fullName, setFullName] = useState("");
  const [bio, setBio] = useState("");
  const [mobileNo, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [twitter, setTwitter] = useState("");
  function reloadPage() {
    window.location.reload();
  }

  useEffect(() => {
    const hasReloaded = localStorage.getItem('hasReloaded');
    if (!hasReloaded) {
      // alert("hiiiiiiiiiii")
      localStorage.setItem('hasReloaded', 'true');
      window.location.reload();
    }
  }, []);

  const userId = localStorage.getItem("user_id");
  useEffect(() => {
    // Fetch user data from the API
    fetch(getAdminVenueProfile + userId)
      .then((response) => response.json())
      .then((data) => {
        const user = data.data.user;
        console.log(user.name);
        setFullName(user.name);
        setBio(user.bio);
        setMobile(user.mobileNumber);
        setAddress(user.address);
        setEmail(user.email);
        setCountry(user.country);
        setFacebook(user.facebook);
        setInstagram(user.instagram);
        setTwitter(user.twitter);
      })
      .catch((error) => console.error("Error fetching user data:", error));
  }, []);

  const [editable, setEditable] = useState(false);
  const [editedData, setEditedData] = useState({ ...userData });
  const [isReadOnly, setReadOnly] = useState(true);

  const handleEditClick = () => {
    setReadOnly(false);
    setEditable(true);
  };

  const handleSaveClick = async () => {
    try {
      const formData = new FormData();
      formData.append("user_id", userId);
      formData.append("name", fullName);
      formData.append("bio", bio);
      formData.append("mobileNumber", mobileNo);
      formData.append("email", email);
      formData.append("address", address);
      formData.append("country", country);
      formData.append("facebook", facebook);
      formData.append("instagram", instagram);
      formData.append("twitter", twitter);

      const response = await fetch(
        `${updateUser}`,
        {
          method: "PUT",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to save data: ${response.statusText}`);
      }

      console.log("Data saved successfully");
      reloadPage();

      setEditable(false);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setEditedData((prevData) => ({ ...prevData, [id]: value }));
  };
  return (
    <div>
      <Header></Header>
      <main id="main" class="main">
        <div class="pagetitle">
          <h1> Profile</h1>
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li class="breadcrumb-item active"> Profile</li>
            </ol>
          </nav>
        </div>
        <section class="section">
          <div class="card p-3">
            <div class="parent col-md-12 row mt-2">
              <div class="col-md-12" style={{ textAlign: "right" }}>
                <div class="child">
                  <a>
                    {" "}
                    {
                      <button
                        id="btnNewUser"
                        type="button"
                        // data-toggle="modal"
                        // data-target="#modalAdd"
                        class=" btn-round"
                        onClick={handleEditClick}
                      >
                        <i class="bi bi-pencil"></i> Edit Profile
                      </button>
                    }
                  </a>
                </div>
              </div>
            </div>
            <form style={{ padding: "0px 30px 30px 30px" }}>
              <div class="form-group">
                <label class="small mb-1" for="inputFullName">
                  Full Name<font color="red"></font>
                </label>
                <input
                  class="form-control"
                  id="inputFullName"
                  type="text"
                  placeholder=""
                  value={fullName}
                  readOnly={isReadOnly}
                  onChange={(e) => setFullName(e.target.value)}
                />
              </div>
              <div class="form-group">
                <label class="small mb-1" for="inputFullName">
                  Bio<font color="red"></font>
                </label>
                <textarea
                  class="form-control"
                  id="inputBio"
                  type="text"
                  placeholder=""
                  style={{ height: "100px" }}
                  value={bio}
                  readOnly={isReadOnly}
                  onChange={(e) => setBio(e.target.value)}
                />
              </div>

              <div class="form-group">
                <label class="small mb-1" for="inputFullName">
                  Mobile No.<font color="red"></font>
                </label>
                <input
                  class="form-control"
                  id="inputNo"
                  type="text"
                  placeholder=""
                  value={mobileNo}
                  readOnly={isReadOnly}
                  onChange={(e) => setMobile(e.target.value)}
                />
              </div>
              <div class="form-group">
                <label class="small mb-1" for="inputFullName">
                  Email <font color="red"></font>
                </label>
                <input
                  class="form-control"
                  id="inputEmail"
                  type="text"
                  placeholder=""
                  value={email}
                  readOnly={isReadOnly}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div class="form-group">
                <label class="small mb-1" for="inputFullName">
                  Address <font color="red">*</font>
                </label>
                <input
                  class="form-control"
                  id="inputAddress"
                  type="text"
                  placeholder=""
                  value={address}
                  readOnly={isReadOnly}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
              <div class="form-group">
                <label class="small mb-1" for="inputFullName">
                  Country <font color="red"></font>
                </label>
                <input
                  class="form-control"
                  id="inputCountry"
                  type="text"
                  placeholder=""
                  value={country}
                  readOnly={isReadOnly}
                  onChange={(e) => setCountry(e.target.value)}
                />
              </div>

              <div class="form-group">
                <label class="small mb-1" for="inputFullName">
                  Facebook <font color="red"></font>
                </label>
                <input
                  class="form-control"
                  id="inputFacebook"
                  type="text"
                  placeholder=""
                  value={facebook}
                  readOnly={isReadOnly}
                  onChange={(e) => setFacebook(e.target.value)}
                />
              </div>
              <div class="form-group">
                <label class="small mb-1" for="inputFullName">
                  Instagram <font color="red"></font>
                </label>
                <input
                  class="form-control"
                  id="inputInstagram"
                  type="text"
                  placeholder=""
                  value={instagram}
                  readOnly={isReadOnly}
                  onChange={(e) => setInstagram(e.target.value)}
                />
              </div>
              <div class="form-group">
                <label class="small mb-1" for="inputFullName">
                  Twitter <font color="red"></font>
                </label>
                <input
                  class="form-control"
                  id="inputTwitter"
                  type="text"
                  placeholder=""
                  value={twitter}
                  readOnly={isReadOnly}
                  onChange={(e) => setTwitter(e.target.value)}
                />
              </div>

              <div className="form-group">
                {editable && (
                  <button
                    id="btnNewUser"
                    type="button"
                    class=" btn-round"
                    onClick={handleSaveClick}
                  >
                    Save Changes
                  </button>
                )}
              </div>
            </form>

            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            ></div>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default ViewProfile;
