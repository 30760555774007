import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import { TailSpin } from "react-loader-spinner";
import Select2 from 'react-select2-wrapper';
import 'select2/dist/css/select2.css';
import "../../Api/apiUrls";
import $ from 'jquery';
import axios from 'axios';
import { API_URL } from "../../Api/apiUrls";
import AdminUpdateInvoice from "../../Components/Admin_components/adminUpdateInvoice.js";

function AdminInvoice() {
    const [modalUpdateInvoiceIsOpen, setUpdateInvoiceModalIsOpen] =
        useState("");

    const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
    const closeUpdateInvoice = () => {
        setUpdateInvoiceModalIsOpen(false);
    };
    const openUpdateInvoice = (invoiceId) => {
        setSelectedInvoiceId(invoiceId)
        closeUpdateInvoice();
        setUpdateInvoiceModalIsOpen(true);
    };


    const [invoiceData, setInvoiceData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        // Fetch the data from the API
        const fetchData = async () => {
            try {
                const response = await axios.get(`${API_URL}/upload_invoice/get/`);
                if (response.data.success) {
                    setInvoiceData(response.data.data);
                    setIsLoading(false);
                }
            } catch (error) {

                console.error('Error fetching invoice data:', error);
            }
        };

        fetchData();
    }, []);

    const formatDateAndTime = (date, startTime, endTime) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = new Date(date).toLocaleDateString(undefined, options);

        const startTimeObj = new Date(`${date}T${startTime}`);
        const endTimeObj = new Date(`${date}T${endTime}`);

        const formattedStartTime = startTimeObj.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
        const formattedEndTime = endTimeObj.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });

        return `${formattedDate}, ${formattedStartTime} - ${formattedEndTime}`;
    };
    return (
        <div>

            <Header></Header>
            {modalUpdateInvoiceIsOpen && (
                <AdminUpdateInvoice onClose={closeUpdateInvoice} invoiceId={selectedInvoiceId} />
            )}
            <main id="main" class="main">
                <div class="pagetitle">
                    <h1>Invoice</h1>
                </div>
                <section class="section">
                    <div class="card p-2">
                        <div class="col-12 col-md-12 col-lg-12 row">
                            <div class="col-md-7">

                            </div>
                            <div class="col-md-5" style={{ textAlign: 'right' }}>
                            </div>
                        </div>

                        <div
                            style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
                        >
                            {/* <TailSpin color="black" width={50} /> */}
                        </div>
                        <div className="table-responsive">
                            {isLoading === true ? (
                                <div style={{ marginTop: "50px", marginLeft: "50%" }}>
                                    <TailSpin color="black" width={50} />
                                </div>
                            ) : invoiceData.length > 0 ? (
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Sr.No</th>
                                            <th scope="col">Instructor Details</th>
                                            <th scope="col">Title</th>
                                            <th scope="col">Slot With Appointment Date</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Uploaded Invoice</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {invoiceData.map((item, index) => {
                                            const { workshop_slot } = item;
                                            // Safely handle the case where workshop_slot or its properties are undefined
                                            const date = workshop_slot?.date;
                                            const startTime = workshop_slot?.startTime;
                                            const endTime = workshop_slot?.endTime;

                                            return (
                                                <tr key={item.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.user.name}<br />
                                                        {item.user.email}<br />
                                                        {item.user.mobileNumber}
                                                    </td>
                                                    <td>{item.workshop.name}</td>
                                                    <td>
                                                        {date && startTime && endTime ? (
                                                            formatDateAndTime(date, startTime, endTime)
                                                        ) : (
                                                            "Slot information not available"
                                                        )}
                                                    </td>
                                                    <td>{item.status === 'Accepted'
                                                        ? "Approved"
                                                        : item.status === 'Pending'
                                                            ? "Pending"
                                                            : item.status === 'Rejected'
                                                                ? "Rejected"
                                                                : "-"}</td>
                                                    <td>
                                                        <a  href={`https://artiquity.in${item.invoice}`} target="_blank" rel="noopener noreferrer">
                                                            View Invoice
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <a
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => openUpdateInvoice(item.id)}
                                                        >
                                                            <i class="bi bi-eye"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            ) : (
                                <h3 style={{ textAlign: "center" }}>No data available</h3>
                            )}
                        </div>

                    </div>
                    <div>
                        {" "}
                        <p
                            style={{
                                backgroundColor: "#000",
                                color: "white",
                                padding: "10px",
                            }}
                        >
                            {invoiceData.length}  Total
                        </p>
                    </div>
                </section>
            </main>
            <Footer></Footer>

        </div>
    )
}

export default AdminInvoice
