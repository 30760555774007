import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import "bootstrap-icons/font/bootstrap-icons.css";
import { TailSpin } from "react-loader-spinner";
import "./Style.css";
import "../../Api/apiUrls";
import { getAdminSchedulePass } from "../../Api/apiUrls";
import DeleteSchedule from "../../Components/Admin_components/deleteSchedule.js";
import AddSchedule from "../../Components/Admin_components/addSchedule.js";

function SchedulePass() {
    const [modalDeleteScheduleIsOpen, setDeleteScheduleIsOpen] = useState(false);
    const [schedule, setSchedule] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [dateTime, setDateTime] = useState("");
    const [selectedScheduleId, setSelectedScheduleId] = useState(null);
    const [modalAddScheduleIsOpen, setAddScheduleModalIsOpen] = useState("");
    function getCurrentDateTime() {
        const now = new Date();
    
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, "0");
        const day = String(now.getDate()).padStart(2, "0");
    
        const hours = String(now.getHours()).padStart(2, "0");
        const minutes = String(now.getMinutes()).padStart(2, "0");
        const seconds = String(now.getSeconds()).padStart(2, "0");
        // const milliseconds = String(now.getMilliseconds()).padStart(3, "0");
    
        const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    
        return formattedDateTime;
      }
    const openAddSchedule = () => {
        closeAddSchedule();
        const currentDateTime = getCurrentDateTime();
        setDateTime(currentDateTime);
        setAddScheduleModalIsOpen(true);
    };
    const closeAddSchedule = () => {
        setAddScheduleModalIsOpen(false);
    };
    const openDeleteSchedule = (scheduleId) => {
        closeDeleteSchedule();
        setSelectedScheduleId(scheduleId);
        setDeleteScheduleIsOpen(true);
    };

    const closeDeleteSchedule = () => {
        setDeleteScheduleIsOpen(null);
    };
    const formatCreatedAt = (createdAt) => {
        const createdAtDate = new Date(createdAt);
        // Format the date part
        const datePart = createdAtDate.toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
        });
        return `${datePart}`;
    };

    useEffect(() => {
        fetch(getAdminSchedulePass)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((data) => {
                const schedule = data.data.PassSchedule;
                setSchedule(schedule);

            })
            .catch((error) => console.error("Error fetching data:", error))
            .finally(() => {
                setIsLoading(false);
            });
    }, []);


    const [searchQuery, setSearchQuery] = useState("");

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredSchedule = Array.isArray(schedule)
        ? schedule.filter((schedule) => {
            const values = Object.values(schedule).map((value) =>
                typeof value === "string" ? value.toLowerCase() : ""
            );
            const isSearchMatch = values.some((value) =>
                value.includes(searchQuery.toLowerCase())
            );
            return isSearchMatch;
        })
        : [];
    return (
        <div>
            <Header></Header>
            {modalDeleteScheduleIsOpen && (
                <DeleteSchedule
                    scheduleId={selectedScheduleId}
                    onClose={closeDeleteSchedule}
                />
            )}
             {modalAddScheduleIsOpen && (
                <AddSchedule onClose={closeAddSchedule} dateTime={dateTime} />
            )}
            <main id="main" class="main">
                <div class="pagetitle">
                    <h1>Schedule</h1>
                </div>
                <section class="section">
                    <div class="card p-2">
                        <div class="parent col-md-12 row mt-2">
                            <div class="col-md-6">
                                <div class="child">
                                    <input
                                        onChange={handleSearchChange}
                                        value={searchQuery}
                                        id="myInput"
                                        type="text"
                                        style={{
                                            borderRadius: "5px",
                                            height: "35px",
                                            padding: "10px",
                                            border: "1px solid lightgrey ",
                                        }}
                                        placeholder="Search..."
                                    />
                                </div>
                            </div>

                            <div class="col-md-6" style={{ textAlign: "right" }}>
                                <div class="child">
                                    <a>
                                        {" "}
                                        <button
                                            id="btnNewUser"
                                            type="button"
                                            data-toggle="modal"
                                            data-target="#modalAdd"
                                            class=" btn-round"
                                          onClick={openAddSchedule}
                                        >
                                            <i class="bi bi-person-add"></i> Add Schedule
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}>
                        </div>
                        <div class="table-responsive">
                            {isLoading === true ? (
                                <div
                                    style={{
                                        marginTop: "50px",
                                        display: "flex",
                                        marginLeft: "50%",
                                    }}
                                >
                                    <TailSpin color="black" width={50} />
                                </div>
                            ) : schedule.length > 0 ? (
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Sr.No</th>
                                            <th scope="col">Venue</th>
                                            <th scope="col">Year</th>
                                            <th scope="col">Month</th>
                                            <th scope="col">Week</th>
                                            <th scope="col">Workshop</th>
                                            <th scope="col">Workshop Slot </th>
                                            <th scope="col">Created On</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody id="tableBody">
                                        {filteredSchedule.map((schedule, index) => (
                                            <tr key={schedule.id}>
                                                <th scope="row">{index + 1}</th>
                                                <td>{schedule?.venue?.name || "---"}</td>
                                                <td>{schedule.year || "---"}</td>
                                                <td>
                                                    {schedule.month
                                                        ? new Date(`1970-${schedule.month}-01`).toLocaleString('en-US', { month: 'long' })
                                                        : "---"}
                                                </td>
                                                <td>
                                                    {schedule.week
                                                        ? `${parseInt(schedule.week)}${["th", "st", "nd", "rd"][
                                                        (parseInt(schedule.week) % 10 > 3 || parseInt(schedule.week) % 10 === 0 || [11, 12, 13].includes(parseInt(schedule.week))) ? 0 : parseInt(schedule.week) % 10
                                                        ]} week`
                                                        : "---"}
                                                </td>

                                                <td>{schedule.workshop.name || "---"}</td>
                                                <td>{formatCreatedAt(schedule.workshop_slot.date || "---")}
                                                    <br></br>
                                                    {schedule.workshop_slot.startTime
                                                        ? new Date(`1970-01-01T${schedule.workshop_slot.startTime}`).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
                                                        : "---"}
                                                    -
                                                    {schedule.workshop_slot.endTime
                                                        ? new Date(`1970-01-01T${schedule.workshop_slot.endTime}`).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
                                                        : "---"}


                                                </td>
                                                <td>{formatCreatedAt(schedule.created_at)}</td>
                                                <td>
                                                    <div className="social-links mt-2">
                                                        &nbsp;&nbsp;
                                                        <a
                                                            style={{ cursor: "pointer" }}
                                                          onClick={() => openDeleteSchedule(schedule.id)}
                                                        >
                                                            <i class="bi bi-trash3"></i>
                                                        </a>
                                                        <br />
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}

                                    </tbody>

                                </table>

                            ) : (
                                <h3 style={{ textAlign: "center" }}> No data available </h3>
                            )}


                        </div>
                    </div>
                    <div>
                        {" "}
                        <p
                            style={{
                                backgroundColor: "#000",
                                color: "white",
                                padding: "10px",
                            }}
                        > {filteredSchedule.length} Total
                        </p>
                    </div>
                </section>
            </main>
            <Footer></Footer>

        </div>
    )
}

export default SchedulePass
