import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "./cancelForm.css";
import "../../Api/apiUrls.js";
import { updateCustomerCancelForm } from "../../Api/apiUrls";
function CancelForm({ onClose, id }) {
  console.log(id);
  const [modalCancelFormIsOpen, setCancelFormModalIsOpen] = useState("");

  const [isAddingReport, setIsAddingReport] = useState(false);
  const [isUpdatingReport, setIsUpdatingReport] = useState(false);
  const [report, setReport] = useState("");
  const closeCancelForm = () => {
    setCancelFormModalIsOpen(false);
  };

  function reloadPage() {
    window.location.reload();
  }
  const handleUpdate = () => {
    setIsUpdatingReport(true);
    const formData = new FormData();
    formData.append("Appointment_id", id);
    formData.append("reasonOfCancellation", report);
    formData.append("status", "Cancelled");

    fetch(updateCustomerCancelForm, {
      method: "PUT",

      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert("Submitted!!");
          setIsUpdatingReport(false);
          window.location.reload();
          onClose();
        } else {
          console.error("Error submitting :", data.message);
          setIsUpdatingReport(false);
        }
      })
      .catch((error) => {
        console.error("Error updating blogs:", error);
        setIsUpdatingReport(false);
      });
  };
  return (
    <div>
      {modalCancelFormIsOpen && <CancelForm onClose={closeCancelForm} />}
      <div className="popup-background">
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "75%",
              height: "80%",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Cancellation Form</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "scroll", padding: "2%" }}
            >
              <form>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Reason for Cancellation
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={report}
                    style={{ height: "100px" }}
                    onChange={(e) => setReport(e.target.value)}
                    // value={workshopDescription}
                    // onChange={(e) => setWorkshopDescription(e.target.value)}
                  />

                  <div class="valid-feedback">Thank you!</div>
                </div>
              </form>
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
              >
                Close
              </button>
              {isAddingReport === true ? (
                <div>
                  <ThreeDots color="#000" height={50} width={50} />
                </div>
              ) : (
                <button
                  id="btnAdd"
                  type="button"
                  class="btn btn-primary "
                  style={{ background: "#000 ", border: "none" }}
                  onClick={handleUpdate}
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CancelForm;
