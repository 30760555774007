import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import { TailSpin } from "react-loader-spinner";
import "../../Api/apiUrls";
import { getAdminPass } from "../../Api/apiUrls";
import AddPass from "../../Components/Admin_components/addPass.js";
import UpdatePass from "../../Components/Admin_components/updatePass.js";

function Pass() {
  const [modalAddPassIsOpen, setAddPassModalIsOpen] = useState("");
  const [modalUpdatePassIsOpen, setUpdatePassModalIsOpen] = useState("");
  const [pass, setPass] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [SelectedeventInstructor, setSelectedEventInstructor] = useState("");
  const [workshopSelectedCity, setSelectedWorkshopCity] = useState(null);
  const [workshopSelectedLocation, setSelectedWorkshopLocation] = useState(null);
  const [selectedPassId, setSelectedPassId] = useState(null);
  const [selectedeventStatus, setSelectedEventStatus] = useState("");
  const [SelectedeventType, setSelectedEventType] = useState("");

  const [SelectedTitle, setSelectedTitle] = useState("");
  const [SelectedDescription, setSelectedDescription] = useState("");
  const [SelectedMrp, setSelectedMrp] = useState("");
  const [SelectedPrice, setSelectedPrice] = useState("");

  const openAddPass = () => {
    closeAddPass();
    setAddPassModalIsOpen(true);
  };

  const closeAddPass = () => {
    setAddPassModalIsOpen(false);
  };

  const closeUpdatePass = () => {
    setUpdatePassModalIsOpen(false);
  };

  const openUpdatePass = (passId,
    title,
    description,
    workshopCity,
    workshopLocation,
    eventInstructor,
    eventType,
    passMrp,
    passPrice,
    eventStatus,


  ) => {
    setSelectedPassId(passId);
    setSelectedTitle(title);
    setSelectedDescription(description);
    setSelectedWorkshopCity(workshopCity);
    setSelectedWorkshopLocation(workshopLocation);
    setSelectedEventInstructor(eventInstructor);
    setSelectedEventType(eventType);
    setSelectedMrp(passMrp);
    setSelectedPrice(passPrice);
    setSelectedEventStatus(eventStatus);
    closeUpdatePass();
    setUpdatePassModalIsOpen(true);
  };


  useEffect(() => {
    fetch(getAdminPass)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const pass = data.data.Pass;
        setPass(pass);

      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);


  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredEvents = Array.isArray(pass)
    ? pass.filter((pass) => {
      const values = Object.values(pass).map((value) =>
        typeof value === "string" ? value.toLowerCase() : ""
      );
      const isSearchMatch = values.some((value) =>
        value.includes(searchQuery.toLowerCase())
      );
      return isSearchMatch;
    })
    : [];
  const formatCreatedAt = (createdAt) => {
    const createdAtDate = new Date(createdAt);

    // Format the date part
    const datePart = createdAtDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });

    // Format the time part
    const timePart = createdAtDate.toLocaleTimeString("en-GB", {
      hour: "numeric",
      minute: "numeric",

      hour12: true,
    });

    return `${datePart}`;
  };
  return (
    <div>
      <Header></Header>

      {modalAddPassIsOpen && (
        <AddPass onClose={closeAddPass} />
      )}
      {modalUpdatePassIsOpen && (
        <UpdatePass 
        passId={selectedPassId}
        title={SelectedTitle}
        description={SelectedDescription}
        workshopCity={workshopSelectedCity}
        workshopLocation={workshopSelectedLocation}
        eventInstructor={SelectedeventInstructor}
        eventType={SelectedeventType}
        passMrp={SelectedMrp}
        passPrice={SelectedPrice}
        eventStatus={selectedeventStatus}

        onClose={closeUpdatePass} />
      )}

      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Passes</h1>
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="col-md-6">
                <div class="child">
                  <input
                    onChange={handleSearchChange}
                    value={searchQuery}
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                    }}
                    placeholder="Search..."
                  />
                </div>
              </div>

              <div class="col-md-6" style={{ textAlign: "right" }}>
                <div class="child">
                  <a>
                    {" "}
                    <button
                      id="btnNewUser"
                      type="button"
                      data-toggle="modal"
                      data-target="#modalAdd"
                      class=" btn-round"
                      onClick={openAddPass}
                    >
                      <i class="bi bi-person-add"></i> Add Pass
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}>
            </div>
            <div class="table-responsive">
              {isLoading === true ? (
                <div
                  style={{
                    marginTop: "50px",
                    display: "flex",
                    marginLeft: "50%",
                  }}
                >
                  <TailSpin color="black" width={50} />
                </div>
              ) : pass.length > 0 ? (
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Sr.No</th>
                      <th scope="col">Title</th>
                      <th scope="col">Description</th>
                      <th scope="col">Duration Type</th>
                      <th scope="col">MRP</th>
                      <th scope="col">Offer Price</th>
                      <th scope="col">Sold</th>
                      <th scope="col">Status</th>
                      <th scope="col">Created On</th>
                      {/* <th scope="col">Action</th> */}
                    </tr>
                  </thead>
                  <tbody id="tableBody">
                    {filteredEvents.map((pass, index) => (
                      <tr key={pass.id}>
                        <th scope="row">{index + 1}</th>
                        <td>{pass.title || "--"}</td>
                        <td>{pass.description || "--"}</td>
                        <td>{pass.duration_type ? pass.duration_type.replace("_", " ") : "--"}</td>
                        <td>{pass.mrp || "--"}</td>
                        <td>{pass.price || "--"}</td>
                        <td>{pass.sell || "0"}</td>
                        <td>{pass.status || "--"}</td>
                        <td>{formatCreatedAt(pass.created_at)}</td>
                        {/* <td>
                          <div className="social-links mt-2">
                            <a
                              style={{ cursor: "pointer" }}

                              onClick={() => openUpdatePass(
                                pass.id,
                                pass.title,
                                pass.description,
                                {
                                  value: pass.city.id,
                                  label: pass.city.name,
                                },
                                {
                                  value: pass.area.id,
                                  label: pass.area.name,
                                },
                                {
                                  value: pass.venue.id,
                                  label: pass.venue.name,
                                },
                                { value: pass.id, label: pass.duration_type },
                                pass.mrp,
                                pass.price,
                                { value: pass.id, label: pass.status }



                              )}

                            >
                              <i class="bi bi-pencil"></i>
                            </a>
                            &nbsp;&nbsp;
                            <a
                              style={{ cursor: "pointer" }}
                            // onClick={() => openDeleteEmployee(employee.id)}
                            >
                              <i class="bi bi-trash3"></i>
                            </a>
                            <br />

                          </div>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>

                </table>

              ) : (
                <h3 style={{ textAlign: "center" }}> No data available </h3>
              )}


            </div>
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "10px",
              }}
            > {filteredEvents.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>

    </div>
  )
}

export default Pass
