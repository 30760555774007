import React, { useState, useEffect, useRef } from "react";
import "./instructorChat.css";
import { FaPaperPlane } from "react-icons/fa";
import "../../Api/apiUrls.js";
import {getQuestionChatByQuestionId, addQuestionsChat, } from "../../Api/apiUrls";


function InstructorChat({ onClose, questionId }) {

    const [modalInstructorChatIsOpen, setInstructorChatModalIsOpen] = useState("");
    const [chatsData, setChatsData] = useState(null);

    const closeInstructorChat = () => {
        setInstructorChatModalIsOpen(false);
    };
    function reloadPage() {
        window.location.reload();
    }

    const handleClick = (e) => {
        if (e.currentTarget === e.target) {
            onClose();
            reloadPage();
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, [handleClick]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${getQuestionChatByQuestionId}${questionId}`);
                const data = await response.json();
                setChatsData(data);
                console.log(data);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();

    }, []);

    const instructorId = localStorage.getItem("user_id");


    const [message, setMessage] = useState('');

    const send_msg = async () => {
        try {
            setMessage('');  // Clear the message input field

            const formData = new FormData();
            formData.append('question', questionId);
            formData.append('sender', instructorId);
            formData.append('message', message);

            // Make a POST request
            const postResponse = await fetch(`${addQuestionsChat}`, {
                method: 'POST',
                body: formData,
            });

            if (postResponse.ok) {
                const postData = await postResponse.json();
                console.log('POST Success', postData);

                // Make a GET request after a successful POST
                const getResponse = await fetch(`${getQuestionChatByQuestionId}${questionId}`);

                if (getResponse.ok) {
                    const getData = await getResponse.json();
                    console.log('GET Success', getData);

                    // Update your chat data state here if needed
                    setChatsData(getData);
                } else {
                    // Handle error response for the GET request
                    console.error('GET Error:', getResponse.statusText);
                }
            } else {
                // Handle error response for the POST request
                console.error('POST Error:', postResponse.statusText);
                // toast.error('Failed to send message. Please try again.', { position: 'top-center', autoClose: 2000 });
            }
        } catch (error) {
            console.error('Error:', error.message);
            // toast.error('An unexpected error occurred. Please try again.', { position: 'top-center', autoClose: 2000 });
        } finally {
            // Any additional cleanup or logic you want to execute regardless of success or failure
        }
    };


    return (
        <div>
            {modalInstructorChatIsOpen && (
                <InstructorChat onClose={closeInstructorChat} />
            )}
            <div className="popup-background" onClick={handleClick} >
                <div className="popup"   >
                    <div
                        class="modal-content"
                        style={{
                            width: "80%",
                            height: "80%",
                            padding: "2%",
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                        }}

                    >
                        <div
                            class="modal-header"
                            style={{
                                width: "100%",
                                marginBottom: "3%",
                                borderBottom: "solid 1px #7e7a7a8c",
                            }}
                        >
                            <h5
                                class="modal-title"
                                id="modalAddTitle"
                                style={{ marginBottom: "2%" }}
                            >
                                <b>Reply</b>
                            </h5>

                            <button
                                type="button"
                                onClick={() => {
                                    onClose();
                                    reloadPage();
                                }}
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div
                            class="modal-body"
                            style={{ overflowY: "scroll", padding: "2%" }}>

                            {/* <div className="chat-container"> */}
                            {chatsData?.data?.QuestionsChat?.map((chat) => (
                                <div
                                    key={chat.id}
                                    className={
                                        chat.sender.id.toString() === instructorId.toString()
                                            ? 'my-chat'
                                            : 'other-chat'
                                    }
                                >
                                    <div
                                        className="abc"
                                        style={{
                                            borderRadius: 5,
                                            backgroundColor: '#dee2e6',
                                            color: '#000',
                                            padding: '4px 10px',
                                        }}
                                    >
                                        <b style={{ color: '#038c7f' }}>
                                            {chat.sender.id === 1 ? 'Artiquity' : chat.sender.name}
                                        </b>
                                        <br />
                                        {chat.message}
                                    </div>
                                    <span style={{ fontSize: 12, color: '#000' }}>
                                        {new Date(chat.created_at).toLocaleString()}
                                    </span>
                                </div>
                            ))}




                        </div>


                        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <input
                                placeholder="Type your message here..."
                                style={{
                                    color: '#000',
                                    marginRight: 8,
                                    borderRadius: 4,
                                    fontSize: 16,
                                    backgroundColor: '#fff',
                                    paddingLeft: 10,
                                    padding: 8,
                                    width: '100%',
                                    border: 'solid 1px lightgrey',
                                }}
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        send_msg(message);
                                    }
                                }}
                                tabIndex="0"
                            />
                            <FaPaperPlane
                                onClick={() => {
                                    send_msg(message);
                                }}
                                style={{ marginTop: 10, fontSize: 20, cursor: 'pointer' }}
                            />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default InstructorChat
