import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./update_pages.css";
import Select from "react-select";
import "../../Api/apiUrls";
import { UpdateAdminPages } from "../../Api/apiUrls";
function Update_pages({
  onClose,
  UserId,
  PagesTittle,
  PagesName,
  PagesDescription,
  PagesStatus,
}) {
  const [modalUpdate_pagesIsOpen, setUpdate_pagesModalIsOpen] = useState("");
  const [newPagesName, setNewPagesName] = useState(PagesName);
  const [newPagesTitle, setNewPagesTitle] = useState(PagesTittle);
  const [newPagesDescription, setNewPagesDescription] =
    useState(PagesDescription);
  const [newPagesStatus, setNewPagesStatus] = useState(PagesStatus);
  const [isUpdatingPages, setIsUpdatingPages] = useState(false);
  const closeUpdate_pages = () => {
    setUpdate_pagesModalIsOpen(false);
  };

  const handleStatusChange = (selectedOption) => {
    setNewPagesStatus(selectedOption);
  };
  const Status = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];

  function reloadPage() {
    window.location.reload();
  }
  function reloadPage() {
    window.location.reload();
  }

  const handleClick = (e) => {
    if (e.currentTarget === e.target) {
      onClose();
      reloadPage();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);
  const handleUpdate = () => {
    setIsUpdatingPages(true);

    const formData = new FormData();
    formData.append("Pages_id", UserId);
    formData.append("name", newPagesName);
    formData.append("title", newPagesTitle);
    formData.append("description", newPagesDescription);
    formData.append("status", newPagesStatus.label);

    fetch(UpdateAdminPages, {
      method: "PUT",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert("Updated");
          setIsUpdatingPages(false);

          window.location.reload();
          onClose();
        } else {
          console.error("Error updating pages:", data.message);
          setIsUpdatingPages(false);
        }
      })
      .catch((error) => {
        console.error("Error updating pages:", error);
        setIsUpdatingPages(false);
      });
  };
  return (
    <div>
      {modalUpdate_pagesIsOpen && <Update_pages onClose={closeUpdate_pages} />}
      <div className="popup-background">
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "80",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Update Pages</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "scroll", padding: "2%" }}
            >
              <form>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Name
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    value={newPagesName}
                    onChange={(e) => setNewPagesName(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Tittle On Page
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    value={newPagesTitle}
                    onChange={(e) => setNewPagesTitle(e.target.value)}
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Description
                  </label>
                  <textarea
                    type="text"
                    id="selectedImg"
                    class="form-control"
                    value={newPagesDescription}
                    onChange={(e) => setNewPagesDescription(e.target.value)}
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Status
                  </label>
                  <div class="child ml-2">
                    <Select
                      value={newPagesStatus}
                      onChange={handleStatusChange}
                      options={Status}
                    ></Select>
                  </div>
                </div>
              </form>
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
              >
                Close
              </button>

              {isUpdatingPages === true ? (
                <div>
                  <ThreeDots color="#000" height={50} width={50} />
                </div>
              ) : (
                <button
                  id="btnAdd"
                  type="button"
                  class="btn btn-primary"
                  onClick={handleUpdate}
                  style={{ background: "#000 ", border: "none" }}
                >
                  Update
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Update_pages;
