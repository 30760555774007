import React, { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import "../../Api/apiUrls.js";
import { getWorkshoplistById, getSubcategoryWorkshoplistById } from "../../Api/apiUrls";


function Workshoplist({ onClose, id, type }) {
    console.log(type);
    const [modalWorkshoplistIsOpen, setWorkshoplistModalIsOpen] =
        useState("");
    const closeWorkshoplist = () => {
        setWorkshoplistModalIsOpen(false);
    };

    function reloadPage() {
        window.location.reload();
    }
    function reloadPage() {
        window.location.reload();
    }

    const handleClick = (e) => {
        if (e.currentTarget === e.target) {
            onClose();
            reloadPage();
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, [handleClick]);
    const formatCreatedAt = (createdAt) => {
        const createdAtDate = new Date(createdAt);
        return createdAtDate.toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
        });
    };

    const [list, setlist] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    //   useEffect(() => {
    //     fetch(
    //       `${getWorkshoplistById}${id}`
    //     )
    //       .then((response) => {
    //         if (!response.ok) {
    //           throw new Error("Network response was not ok");
    //         }
    //         return response.json();
    //       })
    //       .then((data) => {
    //         const list = data.data.workshops;
    //         // console.log(wishlist);
    //         setlist(list);
    //       })
    //       .catch((error) => console.error("Error fetching data:", error))
    //       .finally(() => {
    //         setIsLoading(false);
    //         // console.log("Wishlist value:", wishlist);
    //       });
    //   }, []);

    useEffect(() => {
        // Determine API based on type
        let apiUrl;
        if (type === "instructor") {
            apiUrl = `${getWorkshoplistById}${id}`;
        } else if (type === "subcategory") {
            apiUrl = `${getSubcategoryWorkshoplistById}${id}`;
        }

        // Fetch the data from the determined API
        fetch(apiUrl)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((data) => {
                // Adjust the data based on the API type
                const list = type === "instructor" ? data.data.workshops : data.data.workshops;
                setlist(list);
            })
            .catch((error) => console.error("Error fetching data:", error))
            .finally(() => {
                setIsLoading(false);
            });
    }, [id, type]); // Re-run the effect when id or type changes


    const tableHeaders = type === "instructor"
        ? ["Sr.No", "Workshop Name", "Online", "Media Count", "Created On"]
        : ["Sr.No", "Workshop Name", "Instructor Details", "Online", "Media Count", "Created On"];



    return (
        <div>
            {modalWorkshoplistIsOpen && (
                <Workshoplist onClose={closeWorkshoplist} />
            )}

            <div className="popup-background" onClick={handleClick}>
                <div className="popup">
                    <div
                        class="modal-content"
                        style={{
                            width: "100%",
                            height: "80%",
                            padding: "2%",
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                        }}
                    >
                        <div
                            class="modal-header"
                            style={{
                                width: "100%",
                                marginBottom: "3%",
                                borderBottom: "solid 1px #7e7a7a8c",
                            }}
                        >
                            <h5
                                class="modal-title"
                                id="modalAddTitle"
                                style={{ marginBottom: "2%" }}
                            >
                                <b>Details</b>
                            </h5>

                            <button
                                type="button"
                                onClick={() => {
                                    onClose();
                                    reloadPage();
                                }}
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="modal-body" style={{ overflowY: "scroll", padding: "2%" }}>
                            {isLoading ? (
                                <div style={{ marginTop: "50px", marginLeft: "50%" }}>
                                    <TailSpin color="black" width={50} />
                                </div>
                            ) : list.length > 0 ? (
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            {tableHeaders.map((header, index) => (
                                                <th scope="col" key={index}>
                                                    {header}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody style={{ fontSize: "13px" }}>
                                        {list.map((item, index) => (
                                            <tr key={item.id}>
                                                <th scope="row">{index + 1}</th>
                                                {type === "instructor" ? (
                                                    <>
                                                        <td>{item.name || "---"}</td>
                                                        <td>{item.online || "---"}</td>
                                                        <td>{item.media_count || "0"}</td>
                                                        <td>{formatCreatedAt(item.created_at)}</td>
                                                    </>
                                                ) : (
                                                    <>
                                                        <td>{item.name || "---"}</td>
                                                        <td>{item.workshopInstructor.name || "---"}<br />
                                                            {item.workshopInstructor.email || "---"}<br />
                                                            {item.workshopInstructor.mobileNumber || "---"}<br />
                                                        </td>
                                                        <td>{item.online || "---"}</td>
                                                        <td>{item.media_count || "0"}</td>
                                                        <td>{formatCreatedAt(item.created_at)}</td>
                                                    </>
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <h5 style={{ textAlign: "center" }}>No data available</h5>
                            )}
                        </div>

                        <div
                            class="modal-footer"
                            style={{
                                padding: "2%",
                                marginTop: "3%",
                                borderTop: "solid 1px #7e7a7a8c",
                            }}
                        >
                            <button
                                type="button"
                                class="btn btn-danger"
                                data-dismiss="modal"
                                onClick={() => {
                                    onClose();
                                    reloadPage();
                                }}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Workshoplist;
