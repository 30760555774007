import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./add_location.css";
import Select from "react-select";
import "../../Api/apiUrls";
import { addLocationCity } from "../../Api/apiUrls";
function Add_city({ onClose }) {
  const [modalAdd_cityIsOpen, setAdd_cityModalIsOpen] = useState("");
  const [cityStatus, setCityStatus] = useState("");
  const [cityName, setCityName] = useState("");
  const [isAddingcity, setIsAddingcity] = useState(false);

  const closeAdd_city = () => {
    setAdd_cityModalIsOpen(false);
  };
  const Status = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];

  function reloadPage() {
    window.location.reload();
  }
  function reloadPage() {
    window.location.reload();
  }

  const handleClick = (e) => {
    if (e.currentTarget === e.target) {
      onClose();
      reloadPage();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  const handleStatusChange = (selectedOption) => {
    setCityStatus(selectedOption.value);
    console.log(selectedOption.value);
  };
  async function addCity(cityName, cityStatus) {
    try {
      const formData = new FormData();
      formData.append("name", cityName);

      formData.append("status", cityStatus);

      // console.log(categoryHomepage);
      const response = await fetch(addLocationCity, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        return true; // Category added successfully
      } else {
        throw new Error("Failed to add city");
      }
    } catch (error) {
      console.error("Error adding city:", error);
      return false; // Category addition failed
    }
  }

  const handleAddCity = async () => {
    if (!cityName) {
      alert("Please fill in all fields");
      return;
    }

    setIsAddingcity(true);

    const result = await addCity(cityName, cityStatus);

    setIsAddingcity(false);

    if (result) {
      alert("City Added Successfully");
      // setSelectedPackage("");
      console.log(cityName);
      setCityName("");
      window.location.reload();
      // setIconFile(null);
      onClose(); // Close the modal
    } else {
      alert("Error Adding City");
    }
  };
  return (
    <div>
      {modalAdd_cityIsOpen && <add_city onClose={closeAdd_city} />}
      <div className="popup-background">
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "auto",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Add City</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "none", padding: "2%" }}
            >
              <form>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Name
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    value={cityName}
                    onChange={(e) => setCityName(e.target.value)}
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Category Status
                  </label>
                  <Select
                    onChange={handleStatusChange}
                    options={Status}
                  ></Select>
                </div>
              </form>
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
              >
                Close
              </button>
              {isAddingcity === true ? (
                <div>
                  <ThreeDots color="#000" height={50} width={50} />
                </div>
              ) : (
                <button
                  id="btnAdd"
                  type="button"
                  class="btn btn-primary "
                  style={{ background: "#000 ", border: "none" }}
                  onClick={handleAddCity}
                >
                  Add
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Add_city;
