import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./update_packages.css";
import Select from "react-select";
import "../../Api/apiUrls";
import { UpdateAdminPackages } from "../../Api/apiUrls";
function Update_packages({
  onClose,
  packageId,
  packageName,
  packageDescription,
  packageDuration,
  packagePrice,
  hsnCode,
  packageType,
  packageStatus,
}) {
  const [modalUpdate_packagesIsOpen, setUpdate_packagesModalIsOpen] =
    useState("");
  const [newPackageName, setNewPackageName] = useState(packageName);
  const [newPackageStatus, setNewPackageStatus] = useState(packageStatus);
  const [newPackageDescription, setNewPackageDescription] =
    useState(packageDescription);
  const [newPackageDuration, setNewPackageDuration] = useState(packageDuration);
  const [newPackagePrice, setNewPackagePrice] = useState(packagePrice);
  const [newHsnCode, setNewHsnCode] = useState(hsnCode);
  const [newPackageType, setNewPackageType] = useState(packageType);
  const [isUpdatingPackage, setIsUpdatingPackage] = useState(false);
  const closeUpdate_packages = () => {
    setUpdate_packagesModalIsOpen(false);
  };
  const duration = [
    { value: "Day", label: "Day" },
    { value: "Week", label: "Week" },
    { value: "Month", label: "Month" },
    { value: "Quarter", label: "Quarter" },
    { value: "Annual", label: "Annual" },
  ];
  const type = [
    { value: "Workshop", label: "Workshop" },
    { value: "Venue", label: "Venue" },
    { value: "News", label: "News" },
  ];
  const status = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];

  function reloadPage() {
    window.location.reload();
  }
  const handleTypeChange = (selectedOption) => {
    setNewPackageType(selectedOption);
  };
  const handleDurationChange = (selectedOption) => {
    setNewPackageDuration(selectedOption);
  };
  const handleStatusChange = (selectedOption) => {
    setNewPackageStatus(selectedOption);
  };
  const handleUpdate = () => {
    setIsUpdatingPackage(true);

    const formData = new FormData();
    formData.append("Package_id", packageId);
    formData.append("name", newPackageName);
    formData.append("Details", newPackageDescription);
    formData.append("duration", newPackageDuration.label);
    formData.append("price", newPackagePrice);
    formData.append("hsn_code", newHsnCode);
    formData.append("type", newPackageType.label);
    formData.append("status", newPackageStatus.label);

    fetch(UpdateAdminPackages, {
      method: "PUT",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert("Updated");
          setIsUpdatingPackage(false);

          window.location.reload();
          onClose();
        } else {
          console.error("Error updating package:", data.message);
          setIsUpdatingPackage(false);
        }
      })
      .catch((error) => {
        console.error("Error updating package:", error);
        setIsUpdatingPackage(false);
      });
  };
  function reloadPage() {
    window.location.reload();
  }

  const handleClick = (e) => {
    if (e.currentTarget === e.target) {
      onClose();
      reloadPage();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);
  return (
    <div>
      {modalUpdate_packagesIsOpen && (
        <Update_packages onClose={closeUpdate_packages} />
      )}
      <div className="popup-background">
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "80%",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Update Packages</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "scroll", padding: "2%" }}
            >
              <form>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Name
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    value={newPackageName}
                    onChange={(e) => setNewPackageName(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Description
                  </label>
                  <textarea
                    type="text"
                    id="selectedImg"
                    class="form-control"
                    value={newPackageDescription}
                    onChange={(e) => setNewPackageDescription(e.target.value)}
                  />
                </div>

                <div class="form-group">
                  <label
                    class="small mb-1"
                    for="inputFullName"
                    value={newPackageDuration}
                    onChange={(e) => setNewPackageDuration(e.target.value)}
                  >
                    Duration
                  </label>
                  <Select
                    value={newPackageDuration}
                    onChange={handleDurationChange}
                    options={duration}
                  ></Select>
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Price
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    value={newPackagePrice}
                    onChange={(e) => setNewPackagePrice(e.target.value)}
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    HSN Code
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    value={newHsnCode}
                    onChange={(e) => setNewHsnCode(e.target.value)}
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Category Type
                  </label>
                  <Select
                    value={newPackageType}
                    onChange={handleTypeChange}
                    options={type}
                  ></Select>
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Category Status
                  </label>
                  <Select
                    onChange={handleStatusChange}
                    value={newPackageStatus}
                    options={status}
                  ></Select>
                </div>
              </form>
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
              >
                Close
              </button>

              {/* <div>
                <ThreeDots color="#000" height={50} width={50} />
              </div> */}

              <button
                id="btnAdd"
                type="button"
                class="btn btn-primary "
                style={{ background: "#000 ", border: "none" }}
                onClick={handleUpdate}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Update_packages;
