import { useEffect, useState, useRef } from 'react';
import { API_URL_WS } from '../Api/apiUrls';
const useWebSocketService = (user_id) => {
  const [socket, setSocket] = useState(null);
  const socketRef = useRef(null);
  // const API_URL='https://vibecopilot.ai';
  const connect = () => {
    const newSocket = new WebSocket(`${API_URL_WS}notifications/?user_id=${user_id}`);
    // const newSocket = new WebSocket(`${API_URL.replace(/^https?/, 'wss')}/ws/notifications/?user_id=${user_id}`);

    // const newSocket = new ReconnectingWebSocket(`ws://127.0.0.1:8000/ws/notifications/?user_id=${user_id}`);
    newSocket.onopen = () => {
      console.log('WebSocket is open now.');
    };

    newSocket.onclose = (event) => {
      console.log(event)
      console.error(`WebSocket closed: ${event.code} - ${event.reason}`);
    };

    newSocket.onerror = (error) => {
      console.error(`WebSocket Error: ${error}`);
    };

    newSocket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      // Handle incoming messages here, you can update state or trigger other actions.
      console.log('Received message:', data);
    };
    console.log(newSocket)
    socketRef.current = newSocket;
    setSocket(newSocket);
  };

  const disconnect = () => {
    if (socketRef.current) {
      socketRef.current.close();
      socketRef.current = null;
    }
  };

  const sendMessage = (message) => {
    if (socket) {
      socket.send(JSON.stringify(message));
    }
  };

  useEffect(() => {
    // Connect on component mount
    connect();

    // Disconnect on component unmount
    return () => {
      disconnect();
    };
  }, [user_id]);

  const setOnMessageHandler = (onMessageHandler) => {

    if (socketRef.current) {
      socketRef.current.onmessage = (event) => {
        const data = JSON.parse(event.data);
        onMessageHandler(data);
      };
    }
  };

  return { sendMessage, disconnect, setOnMessageHandler };
};

export default useWebSocketService;
