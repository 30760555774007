import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./update_categories.css";
import Select from "react-select";
import "../../Api/apiUrls";
import { API_URL, UpdateAdminBanner } from "../../Api/apiUrls";
function Update_banners({
  onClose,
  bannerID,
  bannerTitle,
  bannerPhoto,
  bannerStatus,
}) {
  const handleStatusChange = (selectedOption) => {
    setNewBannerStatus(selectedOption);
  };
  const status = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];
  const [newfile, setNewFile] = useState(null);
  const [modalUpdate_bannersIsOpen, setUpdate_bannersModalIsOpen] =
    useState("");
  const [newBannerTitle, setNewBannerTitle] = useState(bannerTitle);

  const [newBannerStatus, setNewBannerStatus] = useState(bannerStatus);
  const [isUpdatingBanner, setIsUpdatingBanner] = useState(false);
  const closeUpdate_banners = () => {
    setUpdate_bannersModalIsOpen(false);
  };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setNewFile(selectedFile);
    console.log(selectedFile);
  };

  function reloadPage() {
    window.location.reload();
  }

  const handleUpdate = () => {
    setIsUpdatingBanner(true);

    const formData = new FormData();
    formData.append("banner_id", bannerID);
    formData.append("title", newBannerTitle);
    formData.append("status", newBannerStatus.label);

    formData.append("photo", "");
    if (newfile) {
      formData.append("photo", newfile);
    }

    fetch(UpdateAdminBanner, {
      method: "PUT",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert("Updated");
          setIsUpdatingBanner(false);

          window.location.reload();
          onClose();
        } else {
          console.error("Error updating banner:", data.message);
          setIsUpdatingBanner(false);
        }
      })
      .catch((error) => {
        console.error("Error updating banner:", error);
        setIsUpdatingBanner(false);
      });
  };
  function reloadPage() {
    window.location.reload();
  }

  const handleClick = (e) => {
    if (e.currentTarget === e.target) {
      onClose();
      reloadPage();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);
  return (
    <div>
      {modalUpdate_bannersIsOpen && (
        <Update_banners onClose={closeUpdate_banners} />
      )}
      <div className="popup-background">
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "80",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Update Banner</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "none", overflowY: "scroll", padding: "2%" }}
            >
              <form>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Tittle<font color="red">*</font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    value={newBannerTitle}
                    onChange={(e) => setNewBannerTitle(e.target.value)}
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputDocument">
                    Cover Image
                  </label>
                  <br />
                  <input
                    type="file"
                    id="bannerSelectedImg"
                    name="document"
                    onChange={handleFileChange}
                  />
                  <br />
                  <br />
                  <img
                    src={`${API_URL}/${bannerPhoto}`}
                    // alt={category.name || 'No Name'}
                    style={{ width: "60px", height: "60px" }}
                  />
                </div>
                <div class="form-group">
                  <label
                    class="small mb-1"
                    for="inputFullName"
                    value={bannerStatus}
                    onChange={(e) => setNewBannerStatus(e.target.value)}
                  >
                    Status
                  </label>
                  <Select
                    value={newBannerStatus}
                    onChange={handleStatusChange}
                    options={status}
                  ></Select>
                </div>
              </form>
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
              >
                Close
              </button>

              {isUpdatingBanner === true ? (
                <div>
                  <ThreeDots color="#000" height={50} width={50} />
                </div>
              ) : (
                <button
                  id="btnAdd"
                  type="button"
                  class="btn btn-primary"
                  onClick={handleUpdate}
                  style={{ background: "#000 ", border: "none" }}
                >
                  Update
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Update_banners;
