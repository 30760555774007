import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import "../../Api/apiUrls";
import { getAdminBanner } from "../../Api/apiUrls";
import { TailSpin } from "react-loader-spinner";
import Add_banners from "../../Components/Admin_components/add_banner.js";
import Update_banners from "../../Components/Admin_components/update_banner.js";
import Delete_banners from "../../Components/Admin_components/delete_banner.js";
import "../../Api/apiUrls.js";
import {API_URL} from "../../Api/apiUrls";

function Banners() {
  const [modalAdd_bannersIsOpen, setAdd_bannersModalIsOpen] = useState("");
  const [modalUpdate_bannersIsOpen, setUpdate_bannersModalIsOpen] =
    useState(false);
  const [selectedBannerTitle, setSelectedBannerTitle] = useState(null);
  const [selectedBannerPhoto, setSelectedBannerPhoto] = useState(null);
  const [selectedBannerStatus, setSelectedBannerStatus] = useState(null);
  const [selectedBannerID, setSelectedBannerID] = useState(null);
  const [banner, setBanner] = useState([]);
  const [modalDelete_bannersIsOpen, setDelete_bannersIsOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const openAdd_banners = () => {
    closeAdd_banners();
    setAdd_bannersModalIsOpen(true);
  };
  const openUpdate_banners = (
    bannerID,
    bannerTitle,
    bannerPhoto,
    bannerStatus
  ) => {
    setSelectedBannerID(bannerID);
    setSelectedBannerTitle(bannerTitle);
    setSelectedBannerPhoto(bannerPhoto);
    setSelectedBannerStatus(bannerStatus);
    closeUpdate_banners();
    setUpdate_bannersModalIsOpen(true);
  };

  const closeUpdate_banners = () => {
    setUpdate_bannersModalIsOpen(false);
  };
  const openDelete_banners = (bannerId) => {
    setSelectedBannerID(bannerId);
    closeDelete_banners();
    setDelete_bannersIsOpen(true);
  };

  const closeDelete_banners = () => {
    setDelete_bannersIsOpen(null);
  };

  const closeAdd_banners = () => {
    setAdd_bannersModalIsOpen(false);
  };
  useEffect(() => {
    fetch(getAdminBanner)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const banner = data.data.Banner;
        console.log(banner);
        setBanner(banner);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
        console.log("Banner value:", banner);
      });
  }, []);
  return (
    <div>
      <Header></Header>
      {modalAdd_bannersIsOpen && <Add_banners onClose={closeAdd_banners} />}

      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Banner</h1>
          {/* <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/categories">Home</a>
              </li>
              <li class="breadcrumb-item active">Banner</li>
            </ol>
          </nav> */}
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="col-md-6">
                <div class="child">
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                    }}
                    placeholder="Search..."
                  />
                </div>
              </div>
              <div class="col-md-6" style={{ textAlign: "right" }}>
                <div class="child">
                  <a>
                    {" "}
                    <button
                      id="btnNewUser"
                      type="button"
                      data-toggle="modal"
                      data-target="#modalAdd"
                      class=" btn-round"
                      onClick={openAdd_banners}
                    >
                      <i class="bi bi-person-add"></i> Add Banner
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            >
              {/* <TailSpin color="black" width={50} /> */}
            </div>

            {isLoading === true ? (
              <div style={{ marginTop: "50px", marginLeft: "50%" }}>
                <TailSpin color="black" width={50} />
              </div>
            ) : banner.length > 0 ? (
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Sr. No.</th>
                    <th scope="col">Cover Image</th>
                    <th scope="col">Title</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody id="tableBody">
                  {banner.map((banner, index) => (
                    <tr key={banner.id}>
                      <th scope="row">{index + 1}</th>
                      <td>
                        {banner.photo ? (
                          <img
                            src={`${API_URL}/${banner.photo}`}
                            style={{ width: "100px", height: "100px" }}
                            alt="Cover"
                          />
                        ) : (
                          <i
                            className="bi bi-folder"
                            style={{ fontSize: "25px" }}
                          ></i>
                        )}
                      </td>
                      <td>
                        {banner.title || "No Name"}
                        <br />
                      </td>
                      <td>
                        {banner.status || "No Name"}
                        <br />
                      </td>
                      <td>
                        <div className="social-links mt-2">
                          <a
                            onClick={() =>
                              openUpdate_banners(
                                banner.id,
                                banner.title,
                                banner.photo,

                                { value: banner.id, label: banner.status }
                              )
                            }
                          >
                            <i className="bi bi-pencil"></i>
                          </a>
                          &nbsp;
                          <a onClick={() => openDelete_banners(banner.id)}>
                            <i className="bi bi-trash3"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
                {/* <div
                  style={{
                    backgroundColor: "#595857",
                    color: "white",
                    padding: "10px",
                    marginBottom: "20px",
                  }}
                ></div> */}
              </table>
            ) : (
              <h3 style={{ textAlign: "center" }}> No data available </h3>
            )}
            {modalUpdate_bannersIsOpen && (
              <Update_banners
                bannerID={selectedBannerID}
                bannerTitle={selectedBannerTitle}
                bannerPhoto={selectedBannerPhoto}
                bannerStatus={selectedBannerStatus}
                onClose={closeUpdate_banners}
              />
            )}

            {modalDelete_bannersIsOpen && (
              <Delete_banners
                bannerId={selectedBannerID}
                onClose={closeDelete_banners}
              />
            )}
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "10px",
              }}
            >
              {banner.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default Banners;
