import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./update_appointment.css";
import "../../Api/apiUrls.js";
import { API_URL, getBlogsAndNewsById } from "../../Api/apiUrls";

function News_eye({ onClose, newsId }) {
  const [modalNews_eyeIsOpen, setNews_eyeModalIsOpen] = useState("");

  const closeNews_eye = () => {
    setNews_eyeModalIsOpen(false);
  };
  const [isLoading, setIsLoading] = useState(true);
  const [news, setNews] = useState("");

  function reloadPage() {
    window.location.reload();
  }
  function reloadPage() {
    window.location.reload();
  }

  const handleClick = (e) => {
    if (e.currentTarget === e.target) {
      onClose();
      reloadPage();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);
  useEffect(() => {
    fetch(`${getBlogsAndNewsById}${newsId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const news = data.data.BlogsAndNews;
        setNews(news);
        console.log(news);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <div>
      {modalNews_eyeIsOpen && <News_eye onClose={closeNews_eye} />}
      <div className="popup-background" onClick={handleClick}>
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "80%",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
              // overflowY: "scroll",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>News</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "scroll", padding: "2%" }}
            >
              <div className="card" style={{ boxShadow: "none" }}>
                <img
                  className="card-img-top"
                  src={`${API_URL}/${news.photo}`}
                  alt="Card cap"
                  style={{
                    width: "100%",
                    borderRadius: "25px",
                    padding: "10px",
                  }}
                />
              </div>
              <div className="card-body">
                <h5 style={{ fontWeight: "600" }}>{news.title}</h5>
                <p style={{ marginBottom: "1rem" }}>{news.shortSummery}</p>
                <p style={{ marginBottom: "1rem", textAlign: "justify" }}>
                  {" "}
                  <small className="text-muted">{news.discription}</small>
                </p>
                <p style={{ marginBottom: "0rem" }}>
                  <small className="text-muted" style={{ fontWeight: "600" }}>
                    {" "}
                    Author
                  </small>
                </p>
                <p style={{ marginBottom: "1rem" }}>
                  <small className="text-muted">{news.news_author?.name}</small>
                </p>
                <p style={{ marginBottom: "0rem" }}>
                  <small className="text-muted" style={{ fontWeight: "600" }}>
                    {" "}
                    Status
                  </small>
                </p>
                <p style={{ marginBottom: "0rem" }}>
                  <small className="text-muted">{news.status}</small>
                </p>
              </div>
            </div>
            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default News_eye;
