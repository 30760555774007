import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import Select from "react-select";
import "../../Api/apiUrls";
import {
    UpdateAdminEmployee
} from "../../Api/apiUrls";

function UpdateEmployee({ employeeId, employeeName, employeeEmail, employeePassword, employeeAccess, employeeStatus, onClose }) {
    console.log(employeeId)
    console.log(employeeAccess)
    const [modalUpdateEmployeeIsOpen, setUpdateEmployeeModalIsOpen] = useState("");
    const [neweventStatus, setNewEventStatus] = useState(employeeStatus);
    // const [newselectedAccess, setNewSelectedAccess] = useState([employeeAccess]);
    const [newname, setNewName] = useState(employeeName);
    const [newemail, setNewEmail] = useState(employeeEmail);
    const [newpassword, setNewPassword] = useState(employeePassword);
    const [isAddingnews, setIsAddingnews] = useState(false);
    const closeUpadteEmployee = () => {
        setUpdateEmployeeModalIsOpen(false);
    };
    function reloadPage() {
        window.location.reload();
    }
    const status = [
        { value: "Active", label: "Active" },
        { value: "Inactive", label: "Inactive" },
    ];
    // Initialize employeeAccess as an array without brackets for display
    const initialAccess = typeof employeeAccess === 'string'
    ? employeeAccess.replace(/[\[\]"]+/g, '').split(',')
    : employeeAccess || [];

    const formattedEmployeeAccess = Array.isArray(initialAccess)
        ? initialAccess.map((access) => ({
            value: access,
            label: access.charAt(0).toUpperCase() + access.slice(1),
        }))
        : [];

    const [newselectedAccess, setNewSelectedAccess] = useState(formattedEmployeeAccess);

    // Update newselectedAccess if employeeAccess changes
    useEffect(() => {
        setNewSelectedAccess(formattedEmployeeAccess);
    }, [employeeAccess]);
    const access = [
        { value: "dashboard", label: "Dashboard" },
        { value: "workshop", label: "Workshop" },
        { value: "venue", label: "Venue" },
        { value: "blog", label: "Blog" },
        { value: "pages", label: "Pages" },
        { value: "categories", label: "Categories" },
        { value: "sub_categories", label: "Sub-categories" },
        { value: "instructor", label: "Instructor" },
        { value: "venue_owner", label: "Venue Provider" },
        { value: "customer", label: "Customer" },
        { value: "review", label: "Review" },
        { value: "location", label: "Location" },
        { value: "packageManagement", label: "Package Management" },
        { value: "transaction", label: "Transaction" },
        { value: "appointment", label: "Appointment" },
        { value: "Banner", label: "Banner" },
        { value: "review_and_rating" , label: "Review and Rating" },
        { value: "aboutUs" , label: "About Us" },
        { value: "subscribersList" , label: "Subscribers List" },
        { value: "happyCustomer" , label: "Happy Customer" },
        { value: "Faq" , label: "Faq" },
        { value: "cancellationPolicy" , label: "Cancellation Policy" },
        { value: "privacyPolicy" , label: "Privacy Policy" },
        { value: "terms&conditions" , label: "Terms & Conditions" },
        { value: "weAreHiring", label: "We are Hiring" },
        { value: "questions", label: "Questions" },
        { value: "messages", label: "Messages" },
        { value: "instructorPayOut", label: "Instructor PayOut" },
        { value: "customerPayOut", label: "Customer PayOut" },
        { value: "invoice", label: "Invoice" },
        { value: "events", label: "Events" },
        { value: "employee", label: "Employee" },
    ];
    const handleStatusChange = (selectedOption) => {
        setNewEventStatus(selectedOption);
    };

    const [isUpdatingnews, setIsUpdatingnews] = useState(false);
    const handleUpdate = () => {
        setIsUpdatingnews(true);
        const formData = new FormData();
        formData.append("user_id", employeeId);
        formData.append("name", newname);
        formData.append("email", newemail);
        formData.append("mobileNumber", "8087848067");
        formData.append("password", newpassword);
        formData.append("status", neweventStatus.label);
        formData.append("type_of_user", "Employee");
        const accessValues = `[${newselectedAccess.map(item => item.value).join(",")}]`;
        formData.append("access", accessValues);


        fetch(UpdateAdminEmployee, {
            method: "PUT",
            // headers: {
            //   'accept': 'application/json',
            //   'Content-Type': 'application/json',
            // },
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    alert("Updated!!");
                    setIsUpdatingnews(false);
                    window.location.reload();
                    onClose();
                } else {
                    console.error("Error updating news:", data.message);
                    setIsUpdatingnews(false);
                }
            })
            .catch((error) => {
                console.error("Error updating news:", error);
                setIsUpdatingnews(false);
            });
    };
    return (
        <div>

            {modalUpdateEmployeeIsOpen && (
                <UpdateEmployee onClose={closeUpadteEmployee} />
            )}

            <div className="popup-background">
                <div className="popup">
                    <div
                        class="modal-content"
                        style={{
                            width: "100%",
                            height: "80%",
                            padding: "2%",
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                        }}
                    >
                        <div
                            class="modal-header"
                            style={{
                                width: "100%",
                                marginBottom: "3%",
                                borderBottom: "solid 1px #7e7a7a8c",
                            }}
                        >
                            <h5
                                class="modal-title"
                                id="modalAddTitle"
                                style={{ marginBottom: "2%" }}
                            >
                                <b>Update Employee</b>
                            </h5>

                            <button
                                type="button"
                                onClick={() => {
                                    onClose();
                                    reloadPage();
                                }}
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div
                            class="modal-body"
                            style={{ overflowY: "scroll", padding: "2%" }}
                        >
                            <form>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Name<font color="red"></font>
                                    </label>
                                    <input
                                        class="form-control"
                                        id="inputFullName"
                                        type="text"
                                        placeholder=""
                                        value={newname}
                                        onChange={(e) => setNewName(e.target.value)}

                                    />
                                </div>


                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Email/Username<font color="red"></font>
                                    </label>
                                    <textarea
                                        class="form-control"
                                        id="inputFullName"
                                        type="text"
                                        placeholder=""
                                        style={{ height: "100px" }}
                                        value={newemail}
                                        onChange={(e) => setNewEmail(e.target.value)}

                                    />
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Password<font color="red"></font>
                                    </label>
                                    <textarea
                                        class="form-control"
                                        id="inputFullName"
                                        type="text"
                                        placeholder=""
                                        value={newpassword}
                                        onChange={(e) => setNewPassword(e.target.value)}

                                    />
                                </div>
                                <div className="form-group">
                                    <label className="small mb-1" htmlFor="inputAccess">
                                        Access<font color="red"></font>
                                    </label>
                                    <Select
                                        isMulti
                                        id="inputAccess"
                                        options={access}
                                        value={newselectedAccess}
                                        onChange={setNewSelectedAccess}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Status<font color="red" style={{ fontSize: "10px" }}>

                                        </font>
                                    </label>
                                    <div class="child ml-2">
                                        <Select
                                            value={neweventStatus}
                                            onChange={handleStatusChange}
                                            options={status}
                                        ></Select>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div
                            class="modal-footer"
                            style={{
                                padding: "2%",
                                marginTop: "3%",
                                borderTop: "solid 1px #7e7a7a8c",
                            }}
                        >
                            <button
                                type="button"
                                class="btn btn-danger"
                                data-dismiss="modal"
                                onClick={() => {
                                    onClose();
                                    reloadPage();
                                }}
                            >
                                Close
                            </button>
                            {isAddingnews === true ? (
                                <div>
                                    <ThreeDots color="#000" height={50} width={50} />
                                </div>
                            ) : (
                                <button
                                    id="btnAdd"
                                    type="button"
                                    class="btn btn-primary "
                                    style={{ background: "#000 ", border: "none" }}
                                    onClick={handleUpdate}
                                >
                                    Update
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default UpdateEmployee
