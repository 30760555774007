import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./update_packages.css";
import Select from "react-select";
import "../../Api/apiUrls";
import {
  API_URL,
  UpdateAdminNews,
  getNewsPackageManagementForUserId,
} from "../../Api/apiUrls";
function Update_news({
  newsId,
  newsName,
  newsImage,
  newsDescription,
  newsSummary,
  newsKeyword,
  newsAuthor,
  newsStatus,
  onClose,
}) {
  const [modalUpdate_newsIsOpen, setUpdate_newsModalIsOpen] = useState("");
  const [newnewsName, setNewNewsName] = useState(newsName);
  const [newnewsDescription, setNewNewsDescription] = useState(newsDescription);
  const [newnewsSummary, setNewNewsSummary] = useState(newsSummary);
  const [newnewsAuthor, setNewNewsAuthor] = useState(newsAuthor);
  const [newNewsStatus, setNewNewsStatus] = useState(newsStatus);
  const [newfile, setNewFile] = useState(null);
  const [newnewsKeyword, setNewNewsKeyword] = useState(newsKeyword);
  const [isUpdatingnews, setIsUpdatingnews] = useState(false);
  const [author, setAuthor] = useState("");
  const [selectedAuthor, setSelectedAuthor] = useState(newsAuthor);
  const status = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];
  const closeUpdate_news = () => {
    setUpdate_newsModalIsOpen(false);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setNewFile(selectedFile);
    console.log(selectedFile);
  };
  const handleStatusChange = (selectedOption) => {
    setNewNewsStatus(selectedOption);
  };

  function reloadPage() {
    window.location.reload();
  }

  const handleClick = (e) => {
    if (e.currentTarget === e.target) {
      onClose();
      reloadPage();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);
  // Log author options for debugging

  const handleUpdate = () => {
    setIsUpdatingnews(true);
    const formData = new FormData();
    formData.append("blog_and_news_id", newsId);
    formData.append("title", newnewsName);
    formData.append("discription", newnewsDescription);
    formData.append("keywords", newnewsKeyword);
    formData.append("shortSummery", newnewsSummary);
    formData.append("news_author", selectedAuthor.value);
    formData.append("status", newNewsStatus.label);
    if (newfile) {
      formData.append("photo", newfile);
    }

    fetch(UpdateAdminNews, {
      method: "PUT",
      // headers: {
      //   'accept': 'application/json',
      //   'Content-Type': 'application/json',
      // },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert("Updated!!");
          setIsUpdatingnews(false);
          window.location.reload();
          onClose();
        } else {
          console.error("Error updating news:", data.message);
          setIsUpdatingnews(false);
        }
      })
      .catch((error) => {
        console.error("Error updating news:", error);
        setIsUpdatingnews(false);
      });
  };

  useEffect(() => {
    fetch(`${getNewsPackageManagementForUserId}`)
      .then((response) => response.json())
      .then((data) => {
        console.log("API Response:", data);

        if (data.success) {
          if (Array.isArray(data.data)) {
            setAuthor(data.data);
          } else {
            console.error("Authors data is not an array:", data.data);
          }
        } else {
          console.error("Error fetching authors:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching authors:", error);
      });
  }, []);
  const handleAuthorChange = (selectedOptions) => {
    console.log(selectedOptions);
    setSelectedAuthor(selectedOptions);
  };

  const newAuthorOptions = Array.isArray(author)
    ? author.map((data) => ({
        value: data.id,
        label: data.name,
      }))
    : [];

  console.log("Author Options:", newAuthorOptions);

  return (
    <div>
      {modalUpdate_newsIsOpen && <Update_news onClose={closeUpdate_news} />}
      <div className="popup-background">
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "80%",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Update News</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "scroll", padding: "2%" }}
            >
              <form>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Name<font color="red"></font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={newnewsName}
                    onChange={(e) => setNewNewsName(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputDocument">
                    Featured Image
                  </label>
                  <br />
                  <input
                    type="file"
                    id="selectedImg"
                    name="document"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                  <img
                    id="selectedImg"
                    src={`${API_URL}/${newsImage}`}
                    // alt={category.name || 'No Name'}
                    style={{ width: "60px", height: "60px" }}
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Description<font color="red"></font>
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={newnewsDescription}
                    onChange={(e) => setNewNewsDescription(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Keywords<font color="red"></font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={newnewsKeyword}
                    onChange={(e) => setNewNewsKeyword(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    One Line Summary<font color="red"></font>
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=" "
                    value={newnewsSummary}
                    onChange={(e) => setNewNewsSummary(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Author <font color="red"></font>
                  </label>

                  <Select
                    options={newAuthorOptions}
                    //  value={selectedCategories}
                    value={selectedAuthor}
                    onChange={handleAuthorChange}
                  ></Select>
                </div>
                {/* <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Enter Keyword<font color="red">*</font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                  />
                </div> */}
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Status: <font color="red"></font>
                  </label>
                  <div class="child ml-2">
                    <Select
                      placeholder="Status"
                      options={status}
                      value={newNewsStatus}
                      onChange={handleStatusChange}
                    ></Select>
                  </div>
                </div>
              </form>
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
              >
                Close
              </button>
              {isUpdatingnews === true ? (
                <div>
                  <ThreeDots color="#000" height={50} width={50} />
                </div>
              ) : (
                <button
                  id="btnAdd"
                  type="button"
                  class="btn btn-primary "
                  style={{ background: "#000 ", border: "none" }}
                  onClick={handleUpdate}
                >
                  Update
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Update_news;
