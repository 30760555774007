import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import Select from "react-select";
import $ from 'jquery';
import Select2 from 'react-select2-wrapper';
import "../../Api/apiUrls";
import { getAdminLocationCity, getAdminLocationArea, API_URL , addAdminPass} from "../../Api/apiUrls";

function AddPass({ onClose }) {
    const [modalAddPassIsOpen, setAddPassModalIsOpen] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    function reloadPage() {
        window.location.reload();
    }

    const closeAddPass = () => {
        setAddPassModalIsOpen(false);
    };

    const [isAddingnews, setIsAddingnews] = useState(false);
    const [eventStatus, setEventStatus] = useState("");
    const [duration_type, setDurationType] = useState("");
    const [mrp, setMrp] = useState("");
    const [price, setPrice] = useState("");
    const [eventTitle, setEventTitle] = useState("");
    const [eventDescription, setEventDescription] = useState("");

    const status = [
        { value: "Active", label: "Active" },
        { value: "Inactive", label: "Inactive" },
    ];

    const handleStatusChange = (selectedOption) => {
        setEventStatus(selectedOption.value);
    };


    const duration = [
        { value: "Monthly", label: "Monthly" },
        { value: "Quaterly", label: "Quaterly" },
        { value: "Half_Yearly", label: "Half Yearly" },
        { value: "Annually", label: "Annually" },
    ];

    const handleDurationChange = (selectedOption) => {
        setDurationType(selectedOption.value);
    };



    async function addPass(
        eventTitle,
        eventDescription,
        eventStatus,
        mrp,
        price,
        duration_type
    ) {
        try {
            const formData = new FormData();

            formData.append("title", eventTitle);
            formData.append("description", eventDescription);
            formData.append("status", eventStatus);
            formData.append("mrp", mrp);
            formData.append("price", price);
            formData.append("duration_type", duration_type);

            const response = await fetch(addAdminPass, {
                method: "POST",
                body: formData,
            });
            const responseData = await response.json();
            if (response.ok) {
                if (responseData.status === 201) {
                    return true;
                } else {
                    return false;
                }
            } else {
                throw new Error("Failed to add Pass");
            }
        } catch (error) {
            console.error("Error adding Pass:", error);
            return false;
        }
    }

    const handleAddPass = async () => {

        setIsAddingnews(true);

        const result = await addPass(
            eventTitle,
            eventDescription,
            eventStatus,
            mrp,
            price,
            duration_type
        );
        setIsAddingnews(false);
        if (result) {
            alert("Pass Added Successfully");
            window.location.reload();
            onClose();
        } else {
            alert("Error Adding Pass");
        }
    };
    return (
        <div>
            {modalAddPassIsOpen && (
                <AddPass onClose={closeAddPass} />
            )}

            <div className="popup-background">
                <div className="popup">
                    <div
                        class="modal-content"
                        style={{
                            width: "100%",
                            height: "80%",
                            padding: "2%",
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                        }}
                    >
                        <div
                            class="modal-header"
                            style={{
                                width: "100%",
                                marginBottom: "3%",
                                borderBottom: "solid 1px #7e7a7a8c",
                            }}
                        >
                            <h5
                                class="modal-title"
                                id="modalAddTitle"
                                style={{ marginBottom: "2%" }}
                            >
                                <b>Add Pass</b>
                            </h5>

                            <button
                                type="button"
                                onClick={() => {
                                    onClose();
                                    reloadPage();
                                }}
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div
                            class="modal-body"
                            style={{ overflowY: "scroll", padding: "2%" }}
                        >
                            <form>
                            <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Title<font color="red"></font>
                                    </label>
                                    <input
                                        class="form-control"
                                        id="inputFullName"
                                        type="text"
                                        placeholder=""
                                        value={eventTitle}
                                        onChange={(e) => setEventTitle(e.target.value)}

                                    />
                                </div>


                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Description<font color="red"></font>
                                    </label>
                                    <textarea
                                        class="form-control"
                                        id="inputFullName"
                                        type="text"
                                        placeholder=""
                                        style={{ height: "100px" }}
                                        value={eventDescription}
                                        onChange={(e) => setEventDescription(e.target.value)}

                                    />
                                </div>

                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Duration Type<font color="red" style={{ fontSize: "10px" }}>

                                        </font>
                                    </label>
                                        <Select
                                            onChange={handleDurationChange}
                                            options={duration}
                                        ></Select>
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        MRP<font color="red"></font>
                                    </label>
                                    <input
                                        class="form-control"
                                        id="inputFullName"
                                        type="number"
                                        placeholder=""
                                        value={mrp}
                                        onChange={(e) => setMrp(e.target.value)}

                                    />
                                </div>


                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Offer Price<font color="red"></font>
                                    </label>
                                    <input
                                        class="form-control"
                                        id="inputFullName"
                                        type="number"
                                        placeholder=""
                                        value={price}
                                        onChange={(e) => setPrice(e.target.value)}

                                    />
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Status<font color="red" style={{ fontSize: "10px" }}>

                                        </font>
                                    </label>
                                    <div class="child ml-2">
                                        <Select
                                            onChange={handleStatusChange}
                                            options={status}
                                        ></Select>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div
                            class="modal-footer"
                            style={{
                                padding: "2%",
                                marginTop: "3%",
                                borderTop: "solid 1px #7e7a7a8c",
                            }}
                        >
                            <button
                                type="button"
                                class="btn btn-danger"
                                data-dismiss="modal"
                                onClick={() => {
                                    onClose();
                                    reloadPage();
                                }}
                            >
                                Close
                            </button>
                            {isAddingnews === true ? (
                                <div>
                                    <ThreeDots color="#000" height={50} width={50} />
                                </div>
                            ) : (
                                <button
                                    id="btnAdd"
                                    type="button"
                                    class="btn btn-primary "
                                    style={{ background: "#000 ", border: "none" }}
                                    onClick={handleAddPass}
                                >
                                    Add
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default AddPass
