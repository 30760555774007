import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./add_categories.css";
import Select from "react-select";
import "../../Api/apiUrls";
import { addAdminPages } from "../../Api/apiUrls";
function Add_pages({ onClose }) {
  const [modalAdd_pagesIsOpen, setAdd_pagesModalIsOpen] = useState("");
  const [PagesName, setPagesName] = useState("");
  const [PagesTitle, setPagesTitle] = useState("");
  const [PagesDescription, setPagesDescription] = useState("");
  const [PagesStatus, setPagesStatus] = useState("");
  const [isAddingpages, setIsAddingpages] = useState(false);

  const closeAdd_pages = () => {
    setAdd_pagesModalIsOpen(false);
  };
  const Status = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];

  const handleStatusChange = (selectedOption) => {
    setPagesStatus(selectedOption.value);
  };

  function reloadPage() {
    window.location.reload();
  }

  async function addPages(
    PagesName,
    PagesTitle,
    PagesDescription,
    PagesStatus
  ) {
    try {
      const formData = new FormData();
      formData.append("name", PagesName);
      formData.append("title", PagesTitle);
      formData.append("description", PagesDescription);
      formData.append("status", PagesStatus);

      const response = await fetch(addAdminPages, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        return true;
      } else {
        throw new Error("Failed to add Pages");
      }
    } catch (error) {
      console.error("Error adding Pages:", error);
      return false;
    }
  }

  const handleAddPages = async () => {
    if (!PagesName) {
      alert("Please fill in all fields");
      return;
    }

    setIsAddingpages(true);

    const result = await addPages(
      PagesName,
      PagesTitle,
      PagesDescription,
      PagesStatus
    );
    setIsAddingpages(false);
    if (result) {
      alert("Pages Added Successfully");
      // console.log(newsStatus);
      window.location.reload();
      onClose();
    } else {
      alert("Error Adding Pages");
    }
  };
  function reloadPage() {
    window.location.reload();
  }

  const handleClick = (e) => {
    if (e.currentTarget === e.target) {
      onClose();
      reloadPage();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  return (
    <div>
      {modalAdd_pagesIsOpen && <Add_pages onClose={closeAdd_pages} />}
      <div className="popup-background">
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "auto",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Add Pages</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "none", padding: "2%" }}
            >
              <form>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Name<font color="red"></font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={PagesName}
                    onChange={(e) => setPagesName(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Tittle On Page<font color="red"></font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={PagesTitle}
                    onChange={(e) => setPagesTitle(e.target.value)}
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Description
                  </label>
                  <textarea
                    type="text"
                    id="selectedImg"
                    class="form-control"
                    placeholder=""
                    style={{ height: "70px" }}
                    value={PagesDescription}
                    onChange={(e) => setPagesDescription(e.target.value)}
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Status <font color="red"></font>
                  </label>
                  <div class="child ml-2">
                    <Select
                      options={Status}
                      onChange={handleStatusChange}
                    ></Select>
                  </div>
                </div>
              </form>
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
              >
                Close
              </button>
              {isAddingpages === true ? (
                <div>
                  <ThreeDots color="#000" height={50} width={50} />
                </div>
              ) : (
                <button
                  id="btnAdd"
                  type="button"
                  class="btn btn-primary "
                  style={{ background: "#000 ", border: "none" }}
                  onClick={handleAddPages}
                >
                  Add
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Add_pages;
