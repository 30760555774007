import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import { TailSpin } from "react-loader-spinner";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import Add_packages from "../../Components/Admin_components/add_packages.js";
import Update_packages from "../../Components/Admin_components/update_packages.js";
import Delete_packages from "../../Components/Admin_components/delete_packages.js";

import "../../Api/apiUrls";
import { getAdminPackages } from "../../Api/apiUrls";
function Packages() {
  const [packages, setPackages] = useState([]);
  const [modalAdd_packagesIsOpen, setAdd_packagesModalIsOpen] = useState("");
  const [modalUpdate_packagesIsOpen, setUpdate_packagesModalIsOpen] =
    useState(false);
  const [selectedPackageId, setSelectedPackageId] = useState(null);
  const [selectedPackageName, setSelectedPackageName] = useState(null);
  const [selectedPackageDescription, setSelectedPackageDescription] =
    useState(null);
  const [selectedPackageDuration, setSelectedPackageDuration] = useState(null);
  const [selectedPackagePrice, setSelectedPackagePrice] = useState(null);
  const [selectedHsnCode, setSelectedHsnCode] = useState(null);
  const [selectedPackageType, setSelectedPackageType] = useState(null);
  const [selectedPackageStatus, setSelectedPackageStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [modalDelete_packagesIsOpen, setDelete_packagesIsOpen] =
    useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const status = [
    { value: "All", label: "All" },
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];


  const openAdd_packages = () => {
    closeAdd_packages();
    setAdd_packagesModalIsOpen(true);
  };

  const closeAdd_packages = () => {
    setAdd_packagesModalIsOpen(false);
  };
  const openUpdate_packages = (
    packageId,
    packageName,
    packageDescription,
    packageDuration,
    packagePrice,
    hsnCode,
    packageType,
    packageStatus
  ) => {
    setSelectedPackageId(packageId);
    setSelectedPackageName(packageName);
    setSelectedPackageDescription(packageDescription);
    setSelectedPackageDuration(packageDuration);
    setSelectedPackagePrice(packagePrice);
    setSelectedHsnCode(hsnCode);
    setSelectedPackageType(packageType);
    setSelectedPackageStatus(packageStatus);
    closeUpdate_packages();
    setUpdate_packagesModalIsOpen(true);
  };

  const closeUpdate_packages = () => {
    setUpdate_packagesModalIsOpen(false);
  };
  const openDelete_packages = (packageId) => {
    setSelectedPackageId(packageId);
    closeDelete_packages();
    setDelete_packagesIsOpen(true);
  };

  const closeDelete_packages = () => {
    setDelete_packagesIsOpen(null);
  };
  useEffect(() => {
    fetch(getAdminPackages)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const packages = data.data.Package;
        console.log(packages);
        setPackages(packages);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const [selectedStatus, setSelectedStatus] = useState("All");
  const [searchQuery, setSearchQuery] = useState('');

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption.value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filtered data based on selected status and search query
  const filteredPackages = packages.filter((pkg) => {
    // Check if any field matches the search query
    return (
      (selectedStatus === "All" || pkg.status === selectedStatus) &&
      (
        pkg.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        pkg.price.toLowerCase().includes(searchQuery.toLowerCase()) ||
        pkg.duration.toLowerCase().includes(searchQuery.toLowerCase()) ||
        pkg.type.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  });

  return (
    <div>
      <Header></Header>
      {modalAdd_packagesIsOpen && <Add_packages onClose={closeAdd_packages} />}
      {modalUpdate_packagesIsOpen && (
        <Update_packages
          packageId={selectedPackageId}
          packageName={selectedPackageName}
          packageDescription={selectedPackageDescription}
          packageDuration={selectedPackageDuration}
          packagePrice={selectedPackagePrice}
          hsnCode={selectedHsnCode}
          packageType={selectedPackageType}
          packageStatus={selectedPackageStatus}
          onClose={closeUpdate_packages}
        />
      )}
      {modalDelete_packagesIsOpen && (
        <Delete_packages
          packageId={selectedPackageId}
          onClose={closeDelete_packages}
        />
      )}
      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Packages</h1>
          {/* <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/categories">Home</a>
              </li>
              <li class="breadcrumb-item active">Packages</li>
            </ol>
          </nav> */}
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="col-md-6">
                <div class="child">
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                    }}
                    onChange={handleSearchChange}
                    value={searchQuery}
                    placeholder="Search..."
                  />
                </div>
                <div className="child ml-2">
                  <Select
                    options={status}
                    placeholder="Select Status"
                    onChange={handleStatusChange}
                  ></Select>
                </div>
              </div>
              <div class="col-md-6" style={{ textAlign: "right" }}>
                <div class="child">
                  <a>
                    {" "}
                    <button
                      id="btnNewUser"
                      type="button"
                      data-toggle="modal"
                      data-target="#modalAdd"
                      class=" btn-round"
                      onClick={openAdd_packages}
                    >
                      <i class="bi bi-person-add"></i> Add Packages
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "52%" }}
            >
              {/* <TailSpin color="black" width={50} /> */}
            </div>

            {isLoading === true ? (
              <div style={{ marginTop: "50px", marginLeft: "50%" }}>
                <TailSpin color="black" width={50} />
              </div>
            ) : packages.length > 0 ? (
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Sr.no</th>
                    <th scope="col">Status</th>
                    <th scope="col">Name </th>
                    <th scope="col">Price</th>
                    <th scope="col">HSN Code</th>
                    <th scope="col">Duration</th>
                    <th scope="col">Type</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody id="tableBody">
                  {filteredPackages.map((packages, index) => (
                    <tr key={packages.id}>
                      <th scope="row">{index + 1}</th>

                      <td>
                        {packages.status || ""}
                        <br />
                      </td>
                      <td>
                        {packages.name || ""}
                        <br />
                      </td>
                      <td>
                        {packages.price || ""}
                        <br />
                      </td>

                      <td>
                        {packages.hsn_code || ""}
                        <br />
                      </td>

                      <td>
                        {packages.duration || ""}
                        <br />
                      </td>
                      <td>
                        {packages.type || ""}
                        <br />
                      </td>

                      <td>
                        <div className="social-links mt-2">
                          <a
                            onClick={() =>
                              openUpdate_packages(
                                packages.id,
                                packages.name,
                                packages.Details,

                                {
                                  value: packages.id,
                                  label: packages.duration,
                                },

                                packages.price,
                                packages.hsn_code,
                                { value: packages.id, label: packages.type },
                                { value: packages.id, label: packages.status }
                              )
                            }
                          >
                            <i class="bi bi-pencil"></i>
                          </a>
                          &nbsp;
                          <a onClick={() => openDelete_packages(packages.id)}>
                            <i class="bi bi-trash3"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <h3 style={{ textAlign: "center" }}> No data available </h3>
            )}
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "10px",
              }}
            >
              {filteredPackages.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default Packages;
