import React from 'react'
import "../../Api/apiUrls";
import { deleteAdminSchedule } from "../../Api/apiUrls";

function DeleteSchedule({scheduleId,onClose}) {
    const handleDelete = () => {
        console.log(scheduleId);
    
        fetch(deleteAdminSchedule + scheduleId, {
          method: "DELETE",
          headers: {
            accept: "application/json",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            // Check if the delete operation was successful
            if (data.success) {
              window.location.reload();
              onClose(); // Close the delete confirmation modal
            } else {
              // Handle the error if the delete operation was not successful
              console.error("Error deleting news:", data.message);
            }
          })
          .catch((error) => {
            console.error("Error deleting news:", error);
          });
      };
      function reloadPage() {
        window.location.reload();
      }
  return (
    <div className="popup-background" >
      <div className="popup">
        <div
          class="modal-content"
          style={{
            width: "75%",
            height: "auto",
            padding: "2%",
            backgroundColor: "#fff",
            borderRadius: "5px",
          }}
        >
          <div
            class="modal-header"
            style={{
              width: "100%",
              marginBottom: "3%",
              borderBottom: "solid 1px #7e7a7a8c",
            }}
          >
            <h5 class="modal-title" style={{ marginBottom: "2%" }}>
              Are you sure?
            </h5>
            <button
              type="button"
              class="close"
              onClick={() => {
                onClose();
                reloadPage();
              }}
              data-dismiss="modal"
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div class="modal-body" style={{ overflowY: "none", padding: "2%" }}>
            <p>Do you want to delete this schedule? This action can't be undone.</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              onClick={() => {
                onClose();
                reloadPage();
              }}
            >
              Cancel
            </button>
            <button
              id="btnDelete"
              type="button"
              class="btn btn-danger"
              onClick={handleDelete}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeleteSchedule
