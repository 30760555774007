import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import Select from "react-select";
import "../../Api/apiUrls";
import {
    UpdateAdminBrand
} from "../../Api/apiUrls";

function UpdateBrandRepresentative({brandId, brandName, brandMobile, brandEmail, brandPassword, brandImage, brandStatus,onClose}) {
    const [modalUpdateBrandIsOpen, setUpdateBrandModalIsOpen] = useState("");
    const [isAddingnews, setIsAddingnews] = useState(false);
    const [newselectedBrandName, setNewSelectedBrandName] = useState(brandName);
    const [newselectedBrandMobile, setNewSelectedBrandMobile] = useState(brandMobile);
    const [newselectedBrandPassword, setNewSelectedBrandPassword] = useState(brandPassword);
    const [newselectedBrandEmail, setNewSelectedBrandEmail] = useState(brandEmail);
    // const [newselectedBrandImage, setNewSelectedBrandImage] = useState();
    const [newselectedBrandStatus, setNewSelectedBrandStatus] = useState(brandStatus);
    const [newfile, setNewFile] = useState(null);
    const closeUpdateBrand = () => {
        setUpdateBrandModalIsOpen(false);
    };
    function reloadPage() {
        window.location.reload();
    }
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setNewFile(selectedFile);
        console.log(selectedFile);
    };
    const status = [
        { value: "Active", label: "Active" },
        { value: "Inactive", label: "Inactive" },
    ];
    const handleStatusChange = (selectedOption) => {
        setNewSelectedBrandStatus(selectedOption);
    };

    const [isUpdatingnews, setIsUpdatingnews] = useState(false);
    const handleUpdate = () => {
        setIsAddingnews(true);
        const formData = new FormData();
        formData.append("brand_representative_id", brandId);
        formData.append("name", newselectedBrandName);
        formData.append("mobileNumber", newselectedBrandMobile);
        formData.append("email", newselectedBrandEmail);
        formData.append("password", newselectedBrandPassword);
        formData.append("status", newselectedBrandStatus.label);
        if (newfile) {
            formData.append("profile_image", newfile);
        }

        fetch(UpdateAdminBrand, {
            method: "PUT",
            // headers: {
            //   'accept': 'application/json',
            //   'Content-Type': 'application/json',
            // },
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    alert("Updated!!");
                    setIsAddingnews(false);
                    window.location.reload();
                    onClose();
                } else {
                    console.error("Error updating brand representative:", data.message);
                    setIsAddingnews(false);
                }
            })
            .catch((error) => {
                console.error("Error updating brand representative:", error);
                setIsAddingnews(false);
            });
    };
  return (
    <div>
            {modalUpdateBrandIsOpen && (
                <UpdateBrandRepresentative onClose={closeUpdateBrand} />
            )}
            <div className="popup-background">
                <div className="popup">
                    <div
                        class="modal-content"
                        style={{
                            width: "100%",
                            height: "80%",
                            padding: "2%",
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                        }}
                    >
                        <div
                            class="modal-header"
                            style={{
                                width: "100%",
                                marginBottom: "3%",
                                borderBottom: "solid 1px #7e7a7a8c",
                            }}
                        >
                            <h5
                                class="modal-title"
                                id="modalAddTitle"
                                style={{ marginBottom: "2%" }}
                            >
                                <b>Update Representative</b>
                            </h5>

                            <button
                                type="button"
                                onClick={() => {
                                    onClose();
                                    reloadPage();
                                }}
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div
                            class="modal-body"
                            style={{ overflowY: "scroll", padding: "2%" }}
                        >
                            <form>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Name<font color="red"></font>
                                    </label>
                                    <input
                                        class="form-control"
                                        id="inputFullName"
                                        type="text"
                                        placeholder=""
                                        value={newselectedBrandName}
                                        onChange={(e) => setNewSelectedBrandName(e.target.value)}

                                    />
                                </div>


                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Mobile No.<font color="red"></font>
                                    </label>
                                    <input
                                        class="form-control"
                                        id="inputFullName"
                                        type="text"
                                        placeholder=""
                                        value={newselectedBrandMobile}
                                        maxLength="10"
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (/^\d*$/.test(value)) setNewSelectedBrandMobile(value); // Ensures only digits
                                        }}
                                    />

                                </div>


                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Email<font color="red"></font>
                                    </label>
                                    <input
                                        class="form-control"
                                        id="inputFullName"
                                        type="text"
                                        placeholder=""
                                        value={newselectedBrandEmail}
                                        onChange={(e) => setNewSelectedBrandEmail(e.target.value)}

                                    />
                                    {!newselectedBrandEmail.includes("@") && (
                                        <p style={{ color: "red", fontSize: "12px" }}>
                                            Please enter a valid email address.
                                        </p>
                                    )}
                                </div>


                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Password<font color="red"></font>
                                    </label>
                                    <input
                                        class="form-control"
                                        id="inputFullName"
                                        type="text"
                                        placeholder=""
                                        value={newselectedBrandPassword}
                                        onChange={(e) => setNewSelectedBrandPassword(e.target.value)}

                                    />
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Profile Picture<font color="red"></font>
                                    </label><br></br>
                                    <input
                                        type="file"
                                        id="selectedImg"
                                        name="document"
                                        accept="image/*"
                                        onChange={handleFileChange}
                                        

                                    />
                                     <img
                                        src={`https://artiquity.in${brandImage}`}
                                        // alt={category.name || 'No Name'}
                                        style={{ width: "60px", height: "60px" }}
                                    />
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Status<font color="red" style={{ fontSize: "10px" }}>

                                        </font>
                                    </label>
                                    <div class="child ml-2">
                                        <Select
                                         value={newselectedBrandStatus}
                                         onChange={handleStatusChange}
                                         options={status}
                                        ></Select>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div
                            class="modal-footer"
                            style={{
                                padding: "2%",
                                marginTop: "3%",
                                borderTop: "solid 1px #7e7a7a8c",
                            }}
                        >
                            <button
                                type="button"
                                class="btn btn-danger"
                                data-dismiss="modal"
                                onClick={() => {
                                    onClose();
                                    reloadPage();
                                }}
                            >
                                Close
                            </button>
                            {isAddingnews === true ? (
                                <div>
                                    <ThreeDots color="#000" height={50} width={50} />
                                </div>
                            ) : (
                                <button
                                    id="btnAdd"
                                    type="button"
                                    class="btn btn-primary "
                                    style={{ background: "#000 ", border: "none" }}
                                    onClick={handleUpdate}
                                >
                                    Update
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>

        </div>
  )
}

export default UpdateBrandRepresentative
