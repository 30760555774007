import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import Select from "react-select";
import $ from 'jquery';
import Select2 from 'react-select2-wrapper';
import "../../Api/apiUrls";
import { getAdminLocationCity, getAdminLocationArea, API_URL , UpdateAdminEvents } from "../../Api/apiUrls";

function UpdatePass({ passId,
    title,
    description,
    workshopCity,
    workshopLocation,
    eventInstructor,
    eventType,
    passMrp,
    passPrice,
    eventStatus
    , onClose }) {
    const [modalUpdatePassIsOpen, setUpdatePassModalIsOpen] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [isAddingnews, setIsAddingnews] = useState(false);
    const [location, setLocation] = useState([]);
    const [city, setCity] = useState([]);
    const [filteredAreas, setFilteredAreas] = useState([]);
    const [newTitle, setNewTitle] = useState(title);
    const [newDescription, setNewDescription] = useState(description);
    const [newMrp, setNewMrp] = useState(passMrp);
    const [newPrice, setNewPrice] = useState(passPrice);
    function reloadPage() {
        window.location.reload();
    }

    const closeUpdatePass = () => {
        setUpdatePassModalIsOpen(false);
    };
    const [neweventStatus, setNewEventStatus] = useState(eventStatus);
    const [newduration_type, setNewDurationType] = useState(eventType);
    const status = [
        { value: "Active", label: "Active" },
        { value: "Inactive", label: "Inactive" },
    ];

    const handleStatusChange = (selectedOption) => {
        setNewEventStatus(selectedOption);
    };


    const duration = [
        { value: "Monthly", label: "Monthly" },
        { value: "Quaterly", label: "Quaterly" },
        { value: "Half_Yearly", label: "Half Yearly" },
        { value: "Annually", label: "Annually" },
    ];

    const handleDurationChange = (selectedOption) => {
        setNewDurationType(selectedOption);
    };


    const [instructors, setInstructors] = useState([]);

    function reloadPage() {
        window.location.reload();
    }
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    useEffect(() => {
        // Fetch data from the API
        fetch(`${API_URL}/venue/get-venue/`)
            .then((response) => response.json())
            .then((data) => {
                // Extract relevant data from the response
                const options = data.data.Venue.map((user) => ({
                    value: user.id,
                    label: user.name,
                    // image: `${API_URL}/${user.profile_image}`,
                }));
                setInstructors(options);
                setFilteredOptions(options); // Initially, set filtered options to all options
                setIsLoading(false);
            })
            .catch((error) => console.error("Error fetching instructors:", error));
    }, []);

    const handleInputChange = (newValue) => {
        setSearchValue(newValue);
        // filterOptions(newValue);
    };

    const [selectedInstructor, setSelectedInstructor] =
        useState(eventInstructor);

    const handleInstructorChange = (selectedOption) => {
        setSelectedInstructor(selectedOption);
    };

    // ---------------------------get area---------------------------------
    const [selectedLocation, setSelectedLocation] = useState(workshopLocation);

    useEffect(() => {
        fetch(`${getAdminLocationArea}`)
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    if (Array.isArray(data.data.LocationArea)) {
                        setLocation(data.data.LocationArea);
                    } else {
                        console.error("City data is not an array:", data.data.LocationArea);
                    }
                } else {
                    console.error("Error fetching categories:", data.message);
                }
            })
            .catch((error) => {
                console.error("Error fetching categories:", error);
            });
    }, []);

    const areaOptions = filteredAreas.map((location) => ({
        value: location.id,
        label: location.name,
    }));
    const handleAreaChange = (selectedOptions) => {
        console.log(selectedOptions);
        setSelectedLocation(selectedOptions);

        console.log(selectedOptions);
    };

    // -----------------get city-------------------------------------------
    const [selectedCity, setSelectedCity] = useState(workshopCity);
    useEffect(() => {
        fetch(`${getAdminLocationCity}`)
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    if (Array.isArray(data.data.LocationCity)) {
                        setCity(data.data.LocationCity);
                    } else {
                        console.error("City data is not an array:", data.data.LocationCity);
                    }
                } else {
                    console.error("Error fetching categories:", data.message);
                }
            })
            .catch((error) => {
                console.error("Error fetching categories:", error);
            });
    }, []);

    const cityOptions = city.map((city) => ({
        value: city.id,
        label: city.name,
    }));
    useEffect(() => {
        if (selectedCity && selectedCity.value) {
            // Check if a city is selected
            console.log(selectedCity);
            const filteredAreasResult = location.filter(
                (location) => location.city.id === selectedCity.value
            );
            setFilteredAreas(filteredAreasResult);
        }
    }, [selectedCity, location]);
    const handleCityChange = (selectedOptions) => {
        console.log(selectedOptions);
        setSelectedCity(selectedOptions);

        console.log(selectedOptions);
    };

    const [isUpdatingnews, setIsUpdatingnews] = useState(false);
    const handleUpdate = () => {
        setIsUpdatingnews(true);
        const formData = new FormData();
        formData.append("pass_id", passId);
        formData.append("title", newTitle);
        formData.append("description", newDescription);
        formData.append("status", neweventStatus.label);
        formData.append("duration_type", newduration_type.label);
        formData.append("instructor", selectedInstructor.value);
        formData.append("mrp", newMrp);
        formData.append("price", newPrice);

        fetch(UpdateAdminEvents, {
            method: "PUT",
            // headers: {
            //   'accept': 'application/json',
            //   'Content-Type': 'application/json',
            // },
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    alert("Updated!!");
                    setIsUpdatingnews(false);
                    window.location.reload();
                    onClose();
                } else {
                    console.error("Error updating news:", data.message);
                    setIsUpdatingnews(false);
                }
            })
            .catch((error) => {
                console.error("Error updating news:", error);
                setIsUpdatingnews(false);
            });
    };
    return (
        <div>
            {modalUpdatePassIsOpen && (
                <UpdatePass onClose={closeUpdatePass} />
            )}

            <div className="popup-background">
                <div className="popup">
                    <div
                        class="modal-content"
                        style={{
                            width: "100%",
                            height: "80%",
                            padding: "2%",
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                        }}
                    >
                        <div
                            class="modal-header"
                            style={{
                                width: "100%",
                                marginBottom: "3%",
                                borderBottom: "solid 1px #7e7a7a8c",
                            }}
                        >
                            <h5
                                class="modal-title"
                                id="modalAddTitle"
                                style={{ marginBottom: "2%" }}
                            >
                                <b>Update Pass</b>
                            </h5>

                            <button
                                type="button"
                                onClick={() => {
                                    onClose();
                                    reloadPage();
                                }}
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div
                            class="modal-body"
                            style={{ overflowY: "scroll", padding: "2%" }}
                        >
                            <form>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Title<font color="red"></font>
                                    </label>
                                    <input
                                        class="form-control"
                                        id="inputFullName"
                                        type="text"
                                        placeholder=""
                                        value={newTitle}
                                        onChange={(e) => setNewTitle(e.target.value)}

                                    />
                                </div>


                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Description<font color="red"></font>
                                    </label>
                                    <textarea
                                        class="form-control"
                                        id="inputFullName"
                                        type="text"
                                        placeholder=""
                                        style={{ height: "100px" }}
                                        value={newDescription}
                                        onChange={(e) => setNewDescription(e.target.value)}

                                    />
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Select City<font color="red"></font>
                                    </label>
                                    <Select
                                        options={cityOptions}
                                        value={selectedCity}
                                        onChange={handleCityChange}
                                    ></Select>
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Select Area <font color="red"></font>
                                    </label>
                                    <Select
                                        options={areaOptions}
                                        value={selectedLocation}
                                        onChange={handleAreaChange}
                                    ></Select>
                                </div>

                                <div className="form-group">
                                    <label className="small mb-1" htmlFor="inputFullName">
                                        Select Venue<font color="red"></font>
                                    </label>
                                    <Select
                                        options={instructors}
                                        // options={filteredOptions}
                                        onInputChange={handleInputChange}
                                        value={selectedInstructor} // value prop for controlled component
                                        onChange={handleInstructorChange} // handle change function
                                        getOptionLabel={(option) => (
                                            <div>
                                                {/* <img
                                                    src={option.image}
                                                    style={{ width: "30px", marginRight: "10px" }}
                                                /> */}
                                                {option.label}
                                            </div>
                                        )}
                                        getOptionValue={(option) => option.value}
                                        isSearchable
                                    />
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Duration Type<font color="red" style={{ fontSize: "10px" }}>

                                        </font>
                                    </label>
                                    <Select
                                        onChange={handleDurationChange}
                                        options={duration}
                                        value={newduration_type}
                                    ></Select>
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        MRP<font color="red"></font>
                                    </label>
                                    <input
                                        class="form-control"
                                        id="inputFullName"
                                        type="text"
                                        placeholder=""
                                        value={newMrp}
                                        onChange={(e) => setNewMrp(e.target.value)}

                                    />
                                </div>


                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Offer Price<font color="red"></font>
                                    </label>
                                    <input
                                        class="form-control"
                                        id="inputFullName"
                                        type="text"
                                        placeholder=""
                                        value={newPrice}
                                        onChange={(e) => setNewPrice(e.target.value)}

                                    />
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Status<font color="red" style={{ fontSize: "10px" }}>

                                        </font>
                                    </label>
                                    <div class="child ml-2">
                                        <Select
                                            onChange={handleStatusChange}
                                            options={status}
                                            value={neweventStatus}
                                        ></Select>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div
                            class="modal-footer"
                            style={{
                                padding: "2%",
                                marginTop: "3%",
                                borderTop: "solid 1px #7e7a7a8c",
                            }}
                        >
                            <button
                                type="button"
                                class="btn btn-danger"
                                data-dismiss="modal"
                                onClick={() => {
                                    onClose();
                                    reloadPage();
                                }}
                            >
                                Close
                            </button>
                            {isAddingnews === true ? (
                                <div>
                                    <ThreeDots color="#000" height={50} width={50} />
                                </div>
                            ) : (
                                <button
                                    id="btnAdd"
                                    type="button"
                                    class="btn btn-primary "
                                    style={{ background: "#000 ", border: "none" }}
                                // onClick={handleAddPass}
                                >
                                    Update
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default UpdatePass
