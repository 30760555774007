import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./update_categories.css";
import Select from "react-select";
import "../../Api/apiUrls";
import { UpdateAdminCategories } from "../../Api/apiUrls";
function Update_categories({
  categoryId,
  categoryName,
  categoryDescription,
  categoryType,
  categoryStatus,

  categoryHomepage,
  onClose,
}) {
  console.log(categoryType);
  const [modalUpdate_categoriesIsOpen, setUpdate_categoriesModalIsOpen] =
    useState("");
  const [newcategoryName, setNewcategoryName] = useState(categoryName);
  const [newcategoryDescription, setNewcategoryDescription] =
    useState(categoryDescription);
  const [newcategoryType, setNewcategoryType] = useState(categoryType);
  const [newcategoryStatus, setNewcategoryStatus] = useState(categoryStatus);
  const [newcategoryHomepage, setNewcategoryHomepage] =
    useState(categoryHomepage);
  const [isUpdatingCategory, setIsUpdatingCategory] = useState(false);
  const closeUpdate_categories = () => {
    setUpdate_categoriesModalIsOpen(false);
  };
  const Status = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];
  const Type = [
    { value: "Workshop", label: "Workshop" },
    { value: "Venue", label: "Venue" },
  ];

  function reloadPage() {
    window.location.reload();
  }

  const handleTypeChange = (selectedOption) => {
    setNewcategoryType(selectedOption);
    // console.log(selectedOption.value);
  };
  const handleStatusChange = (selectedOption) => {
    setNewcategoryStatus(selectedOption);
  };

  const handleFreeVersionChange = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setNewcategoryHomepage(newValue);
  };
  const handleUpdate = () => {
    setIsUpdatingCategory(true);

    const formData = new FormData();
    formData.append("categories_id", categoryId);
    formData.append("name", newcategoryName);
    formData.append("description", newcategoryDescription);
    formData.append("type", newcategoryType.label);
    formData.append("status", newcategoryStatus.label);

    formData.append("homepage", newcategoryHomepage);

    fetch(UpdateAdminCategories, {
      method: "PUT",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert("Updated");
          setIsUpdatingCategory(false);

          window.location.reload();
          onClose();
        } else {
          console.error("Error updating category:", data.message);
          setIsUpdatingCategory(false);
        }
      })
      .catch((error) => {
        console.error("Error updating category:", error);
        setIsUpdatingCategory(false);
      });
  };
  function reloadPage() {
    window.location.reload();
  }

  const handleClick = (e) => {
    if (e.currentTarget === e.target) {
      onClose();
      reloadPage();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  return (
    <div>
      {modalUpdate_categoriesIsOpen && (
        <Update_categories onClose={closeUpdate_categories} />
      )}
      <div className="popup-background">
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "80",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Update Categories</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "scroll", padding: "2%" }}
            >
              <form>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Name<font color="red"></font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={newcategoryName}
                    onChange={(e) => setNewcategoryName(e.target.value)}
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Description
                  </label>
                  <textarea
                    type="text"
                    id="selectedImg"
                    class="form-control"
                    value={newcategoryDescription}
                    onChange={(e) => setNewcategoryDescription(e.target.value)}
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Category Type
                  </label>
                  <Select
                    value={newcategoryType}
                    onChange={handleTypeChange}
                    options={Type}
                  ></Select>
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Status
                  </label>
                  <Select
                    value={newcategoryStatus}
                    onChange={handleStatusChange}
                    options={Status}
                  ></Select>
                </div>
                {/* <div class="form-group" style={{ display: "flex" }}>
                  <input
                    type="checkbox"
                    id="checkbox1"
                    name="freeOption1"
                    // checked={freeVersion}
                    onChange={handleFreeVersionChange}
                  />
                  <label
                    class="small mb-1"
                    for="inputFullName"
                    style={{ alignContent: "center" }}
                  >
                    Show on Home Page
                  </label>
                  <br></br>
                </div> */}
              </form>
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
              >
                Close
              </button>

              {isUpdatingCategory === true ? (
                <div>
                  <ThreeDots color="#000" height={50} width={50} />
                </div>
              ) : (
                <button
                  id="btnAdd"
                  type="button"
                  class="btn btn-primary "
                  style={{ background: "#000 ", border: "none" }}
                  onClick={handleUpdate}
                >
                  Update
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Update_categories;
