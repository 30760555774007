import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import Select from "react-select";
import $ from 'jquery';
import Select2 from 'react-select2-wrapper';
import "../../Api/apiUrls";
import { API_URL, addScheduleTempId, getScheduleByTempId, deleteScheduleTemp, addSchedule } from "../../Api/apiUrls";


function AddSchedule({ dateTime, onClose }) {
    console.log(dateTime);
    const [isLoading, setIsLoading] = useState(true);
    const [isAddingnews, setIsAddingnews] = useState(false);
    const [isAddingFaq, setIsAddingFaq] = useState(false);
    const [modalAddScheduleIsOpen, setAddScheduleModalIsOpen] = useState("");
    const closeAddSchedule = () => {
        setAddScheduleModalIsOpen(false);
    };

    function reloadPage() {
        window.location.reload();
    }

    const [instructorsVenue, setInstructorsVenue] = useState([]);
    const [selectedInstructorVenue, setSelectedInstructorVenue] = useState(null);
    useEffect(() => {
        fetch(`${API_URL}/venue/get-venue/`)
            .then(response => response.json())
            .then(data => {
                const options = data.data.Venue.map(user => ({
                    id: user.id,
                    text: user.name,
                    // image: `${API_URL}/${user.profile_image}`,
                }));
                setInstructorsVenue(options);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching instructors:', error);
                setIsLoading(false);
            });
    }, []);


    // Helper functions to format the display of options
    const formatOptionVenue = (state) => {
        if (!state.id) return state.text; // Default text

        const instructorVenueNew = instructorsVenue.find(inst => inst.id == state.id);
        if (!instructorVenueNew) return state.text;

        return $(
            `<span>${state.text}</span>`
        );
    };

    const formatOptionSelectionVenue = (state) => {
        if (!state.id) return state.text; // Default text

        const instructorVenueNew = instructorsVenue.find(inst => inst.id == state.id);
        if (!instructorVenueNew) return state.text;

        return $(
            `<span>${state.text}</span>`
        );
    };
    const month = [
        { value: "01", label: "January" },
        { value: "02", label: "February" },
        { value: "03", label: "March" },
        { value: "04", label: "April" },
        { value: "05", label: "May" },
        { value: "06", label: "June" },
        { value: "07", label: "July" },
        { value: "08", label: "August" },
        { value: "09", label: "September" },
        { value: "10", label: "October" },
        { value: "11", label: "November" },
        { value: "12", label: "December" },
    ];
    const [monthChange, setMonthChange] = useState("");
    const handleMonthChange = (selectedOption) => {
        setMonthChange(selectedOption.value);
    };

    const week = [
        { value: "01", label: "1st Week" },
        { value: "02", label: "2nd Week" },
        { value: "03", label: "3rd Week" },
        { value: "04", label: "4th Week" },
    ];
    const [weekChange, setWeekChange] = useState("");
    const handleWeekChange = (selectedOption) => {
        setWeekChange(selectedOption.value);
    };
    const [showFields, setShowFields] = useState(false);
    const handleAddClick = (event) => {
        event.preventDefault();
        setShowFields(true);
    };

    const handleCancelClick = () => {
        setShowFields(false);
    };
    const [selectedYear, setSelectedYear] = useState(null);

    // Generate years from 2023 to the current year
    const generateYearOptions = () => {
        const currentYear = new Date().getFullYear();
        return Array.from({ length: currentYear - 2023 + 1 }, (_, i) => {
            const year = 2023 + i;
            return { value: year, label: year };
        });
    };

    const yearOptions = generateYearOptions();
    const [instructors, setInstructors] = useState([]);
    const [selectedInstructor, setSelectedInstructor] = useState(null);
    useEffect(() => {
        fetch(`${API_URL}/user/get-instructor/`)
            .then(response => response.json())
            .then(data => {
                const options = data.data.users.map(user => ({
                    id: user.id,
                    text: user.name,
                    image: `${API_URL}/${user.profile_image}`,
                }));
                setInstructors(options);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching instructors:', error);
                setIsLoading(false);
            });
    }, []);
    const handleInstructorChange = (instructorId) => {
        setSelectedInstructor(instructorId);
        fetchWorkshopsByInstructor(instructorId);
    };
    const formatOption = (state) => {
        if (!state.id) return state.text; // Default text

        const instructor = instructors.find(inst => inst.id == state.id);
        if (!instructor) return state.text;

        return $(
            `<span><img src="${instructor.image}" class="img-flag" style="width:20px; margin-right: 10px;" />${state.text}</span>`
        );
    };

    const formatOptionSelection = (state) => {
        if (!state.id) return state.text; // Default text

        const instructor = instructors.find(inst => inst.id == state.id);
        if (!instructor) return state.text;

        return $(
            `<span><img src="${instructor.image}" class="img-flag" style="width:20px; margin-right: 10px;" />${state.text}</span>`
        );
    };

    const [workshops, setWorkshops] = useState([]);
    const [selectedWorkshop, setSelectedWorkshop] = useState(null);

    const fetchWorkshopsByInstructor = (instructorId) => {
        fetch(`${API_URL}/workshop/get-workshop_for_website_by_workshop_instructor_id/?workshop_instructor_id=${instructorId}`)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    const workshopOptions = data.data.Workshop.map(workshop => ({
                        id: workshop.id,
                        text: workshop.name,
                    }));
                    setWorkshops(workshopOptions);
                } else {
                    setWorkshops([]);
                }
            })
            .catch(error => {
                console.error('Error fetching workshops:', error);
                setWorkshops([]);
            });
    };
    const handleWorkshopChange = (e) => {
        const workshopId = e.target.value;
        setSelectedWorkshop(workshopId);
        fetchAppointmentSlotsByWorkshop(workshopId);  // Fetch appointment slots for the selected workshop
    };

    const [appointmentSlots, setAppointmentSlots] = useState([]);

    const [selectedAppointmentSlot, setSelectedAppointmentSlot] = useState(null);
    const fetchAppointmentSlotsByWorkshop = (workshopId) => {
        fetch(`${API_URL}/workshop_slot/get-workshop_slot_by_workshop_id/?WorkshopSlot_workshop_id=${workshopId}`)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    const slotOptions = data.data.WorkshopSlot.map(slot => ({
                        id: slot.id,
                        date: slot.date,         // Keep date in raw format
                        startTime: slot.startTime, // Keep start time in raw format
                        endTime: slot.endTime,    // Keep end time in raw format
                    }));
                    setAppointmentSlots(slotOptions); // Set slot options as is
                } else {
                    setAppointmentSlots([]); // Set empty if no data
                }
            })
            .catch(error => {
                console.error('Error fetching appointment slots:', error);
                setAppointmentSlots([]);
            });
    };


    // -------------------------------------Add schedule by tempId--------------------------------------------------------------------------

    const [scheduleTemp, setScheduleTemp] = useState(false);
    const handleAddScheduleTemp = async (event) => {
        try {
            event.preventDefault();

            setIsAddingFaq(true);

            const formData = new FormData();
            const selectedWorkshopData = workshops.find(workshop => workshop.id == selectedWorkshop);
            const selectedSlotData = appointmentSlots.find(appointmentSlot => appointmentSlot.id == selectedAppointmentSlot);
            formData.append("temp_id", dateTime);
            formData.append("year", selectedYear.value);
            formData.append("month", monthChange);
            formData.append("week", weekChange);
            formData.append("workshop", selectedWorkshopData ? selectedWorkshopData.id : "");
            formData.append("workshop_slot", selectedSlotData ? selectedSlotData.id : "");


            const addResponse = await fetch(addScheduleTempId, {
                method: "POST",
                body: formData,
            });

            if (!addResponse.ok) {
                alert("Failed to add FAQ's");
                return;
            }
            const getResponse = await fetch(`${getScheduleByTempId}${dateTime}`);

            if (!getResponse.ok) {
                alert("Failed to fetch FAQs");
                return;
            }

            const getData = await getResponse.json();
            const scheduleData = getData.data.PassSchedule || [];

            setScheduleTemp(scheduleData);
            setShowFields(false);
        } catch (error) {
            console.error("Error handling FAQ:", error);
        } finally {
            setIsAddingFaq(false);
        }
    };

    const formatCreatedAt = (createdAt) => {
        const createdAtDate = new Date(createdAt);
        // Format the date part
        const datePart = createdAtDate.toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
        });
        return `${datePart}`;
    };

    // ----------------------------delete schedule by tempId--------------------------------------------

    const handleDeleteScheduleTemp = (id) => {
        fetch(deleteScheduleTemp + id, {
            method: "DELETE",
            headers: {
                accept: "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                // Check if the delete operation was successful
                if (data.success) {
                    // Trigger the get API after successful deletion
                    return fetch(`${getScheduleByTempId}${dateTime}`);
                } else {
                    // Handle the error if the delete operation was not successful
                    console.error("Error deleting FAQ:", data.message);
                }
            })
            .then((getResponse) => {
                // Check if the get API response is successful
                if (getResponse && getResponse.ok) {
                    return getResponse.json();
                } else {
                    throw new Error("Failed to fetch FAQs");
                }
            })
            .then((getData) => {
                // Update the table with the fetched data
                const updatedData = getData.data.PassSchedule || [];
                setScheduleTemp(updatedData);
            })
            .catch((error) => {
                console.error("Error handling FAQ:", error);
            });
    };


    // --------------------------Add Schedule -----------------------------------------

    async function addMainSchedule(
        selectedInstructorVenue,
        dateTime

    ) {
        try {
            const formData = new FormData();
            formData.append("venue_id", selectedInstructorVenue);
            formData.append("temp_id", dateTime);

            const response = await fetch(addSchedule, {
                method: "PUT",
                body: formData,
            });
            const responseData = await response.json();
            if (response.ok) {
                if (responseData.status === 201) {
                    return true;
                } else {
                    return false;
                }
            } else {
                throw new Error("Failed to add Pass");
            }
        } catch (error) {
            console.error("Error adding Pass:", error);
            return false;
        }
    }

    const handleAddSchedule = async () => {

        setIsAddingnews(true);

        const result = await addMainSchedule(
            selectedInstructorVenue,
            dateTime
        );
        setIsAddingnews(false);
        if (result) {
            alert("Schedule Added Successfully");
            window.location.reload();
            onClose();
        } else {
            alert("Error Adding Schedule");
        }
    };

    return (
        <div>
            {modalAddScheduleIsOpen && (
                <AddSchedule onClose={closeAddSchedule} />
            )}

            <div className="popup-background">
                <div className="popup">
                    <div
                        class="modal-content"
                        style={{
                            width: "100%",
                            height: "80%",
                            padding: "2%",
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                        }}
                    >
                        <div
                            class="modal-header"
                            style={{
                                width: "100%",
                                marginBottom: "3%",
                                borderBottom: "solid 1px #7e7a7a8c",
                            }}
                        >
                            <h5
                                class="modal-title"
                                id="modalAddTitle"
                                style={{ marginBottom: "2%" }}
                            >
                                <b>Add Schedule</b>
                            </h5>

                            <button
                                type="button"
                                onClick={() => {
                                    onClose();
                                    reloadPage();
                                }}
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div
                            class="modal-body"
                            style={{ overflowY: "scroll", padding: "2%" }}
                        >
                            <form>
                                <div className="form-group">
                                    <label className="small mb-1" htmlFor="instructorSelect">
                                        Venue
                                    </label>
                                    {!isLoading ? (
                                        <Select2
                                            id="instructorSelectVenue"
                                            data={instructorsVenue}
                                            value={selectedInstructorVenue}
                                            onChange={(e) => setSelectedInstructorVenue(e.target.value)}
                                            options={{
                                                placeholder: 'Select Venue',
                                                allowClear: true,
                                                templateResult: formatOptionVenue,
                                                templateSelection: formatOptionSelectionVenue,
                                            }}
                                            style={{ width: '100%' }}
                                        />
                                    ) : (
                                        <p>Loading venue...</p>
                                    )}
                                </div>

                                <div>
                                    <div className="form-group">
                                        <button
                                            style={{
                                                fontSize: "14px",
                                                borderRadius: "4px",
                                                backgroundColor: "#000",
                                                color: "#fff",
                                                width: "30%",
                                                height: "34px"
                                            }}
                                            onClick={handleAddClick}
                                        >
                                            + Add
                                        </button>
                                    </div>
                                    {showFields && (
                                        <>
                                            <div
                                                class="col-md-12"
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <div className="form-group col-md-4">
                                                    <label className="small mb-1" htmlFor="yearSelect">Year</label>
                                                    <Select
                                                        id="yearSelect"
                                                        options={yearOptions}
                                                        value={selectedYear}
                                                        onChange={(selectedOption) => setSelectedYear(selectedOption)}
                                                        placeholder="Select..."
                                                        isClearable
                                                    />
                                                </div>

                                                <div class="form-group col-md-4">
                                                    <label class="small mb-1" for="inputFullName">
                                                        Month<font color="red" style={{ fontSize: "10px" }}>

                                                        </font>
                                                    </label>
                                                    <Select
                                                        onChange={handleMonthChange}
                                                        options={month}
                                                    ></Select>
                                                </div>
                                                <div class="form-group col-md-4">
                                                    <label class="small mb-1" for="inputFullName">
                                                        Week<font color="red" style={{ fontSize: "10px" }}>

                                                        </font>
                                                    </label>
                                                    <Select
                                                        onChange={handleWeekChange}
                                                        options={week}
                                                    ></Select>
                                                </div>
                                            </div>
                                            <div
                                                class="col-md-12"
                                            >
                                                <div className="form-group col-md-12">
                                                    <label className="small mb-1" htmlFor="instructorSelect">
                                                        Select Instructor <span style={{ color: 'red', fontSize: '10px' }}>*</span>
                                                    </label>
                                                    {!isLoading ? (
                                                        <Select2
                                                            id="instructorSelect"
                                                            data={instructors}
                                                            value={selectedInstructor}
                                                            // onChange={(e) => setSelectedInstructor(e.target.value)}
                                                            onChange={(e) => handleInstructorChange(e.target.value)}
                                                            options={{
                                                                placeholder: 'Select an instructor',
                                                                allowClear: true,
                                                                templateResult: formatOption,
                                                                templateSelection: formatOptionSelection,
                                                            }}
                                                            style={{ width: '100%' }}
                                                        />
                                                    ) : (
                                                        <p>Loading instructors...</p>
                                                    )}
                                                </div>

                                                <div className="form-group col-md-12">
                                                    <label className="small mb-1" htmlFor="workshopSelect">
                                                        Select Workshop <span style={{ color: 'red', fontSize: '10px' }}>*</span>
                                                    </label>
                                                    <Select2
                                                        id="workshopSelect"
                                                        data={workshops}
                                                        value={selectedWorkshop}
                                                        onChange={handleWorkshopChange}
                                                        options={{
                                                            placeholder: 'Select a workshop',
                                                            allowClear: true,
                                                        }}
                                                        style={{ width: '100%' }}
                                                    />
                                                </div>



                                            </div>

                                            <div
                                                class="col-md-12"
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <div className="form-group col-md-12">
                                                    <label className="small mb-1" htmlFor="appointmentSelect">
                                                        Select Appointment Slot/Date <span style={{ color: 'red', fontSize: '10px' }}>*</span>
                                                    </label>
                                                    <Select2
                                                        id="appointmentSelect"
                                                        data={appointmentSlots.map(slot => {
                                                            // Format date and time
                                                            const formattedDate = new Date(slot.date).toLocaleDateString('en-US', {
                                                                year: 'numeric',
                                                                month: 'long',
                                                                day: 'numeric',
                                                            });
                                                            const startTime = new Date(`1970-01-01T${slot.startTime}`).toLocaleTimeString('en-US', {
                                                                hour: 'numeric',
                                                                minute: '2-digit',
                                                            });
                                                            const endTime = new Date(`1970-01-01T${slot.endTime}`).toLocaleTimeString('en-US', {
                                                                hour: 'numeric',
                                                                minute: '2-digit',
                                                            });

                                                            // Return formatted options for Select2
                                                            return {
                                                                id: slot.id,
                                                                text: `${formattedDate} | ${startTime} - ${endTime}`, // Display in the dropdown
                                                            };
                                                        })}
                                                        value={selectedAppointmentSlot} // Selected value for controlled component
                                                        onChange={(e) => setSelectedAppointmentSlot(e.target.value)} // Handle change
                                                        options={{
                                                            placeholder: 'Select an appointment slot',
                                                            allowClear: true,
                                                        }}
                                                        style={{ width: '100%' }} // Full-width dropdown
                                                    />
                                                </div>

                                            </div>
                                            <div style={{ display: "flex", marginBottom: "20px" }}>
                                                <button
                                                    type="submit"
                                                    style={{
                                                        marginLeft: "10px",
                                                        fontSize: "14px",
                                                        borderRadius: "4px",
                                                        backgroundColor: "#000",
                                                        color: "#fff",
                                                        border: "none",
                                                        padding: "5px 20px",
                                                    }}
                                                    onClick={handleAddScheduleTemp}
                                                >
                                                    Add
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={handleCancelClick}
                                                    style={{
                                                        marginLeft: "10px",
                                                        fontSize: "14px",
                                                        borderRadius: "4px",
                                                        backgroundColor: "lightgrey",
                                                        color: "#000",
                                                        border: "none",
                                                        padding: "5px 20px",
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </>
                                    )}
                                </div>

                                <div className="table-responsive">
                                    {Array.isArray(scheduleTemp) && scheduleTemp.length > 0 ? (
                                        <table
                                            className="table table-striped"
                                            style={{ padding: "0rem" }}
                                        >
                                            <thead>
                                                <tr>
                                                    <th scope="col">Sr.No</th>
                                                    <th scope="col">Year</th>
                                                    <th scope="col">Month</th>
                                                    <th scope="col">Week</th>
                                                    <th scope="col">Workshop</th>
                                                    <th scope="col">Workshop Slot</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {scheduleTemp.map((scheduleTempItem, index) => (
                                                    <tr key={scheduleTempItem.id}>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>
                                                            {scheduleTempItem.year || "-"}
                                                        </td>
                                                        <td>
                                                            {scheduleTempItem.month
                                                                ? new Date(`1970-${scheduleTempItem.month}-01`).toLocaleString('en-US', { month: 'long' })
                                                                : "---"}
                                                        </td>
                                                        <td>
                                                            {scheduleTempItem.week
                                                                ? `${parseInt(scheduleTempItem.week)}${["th", "st", "nd", "rd"][
                                                                (parseInt(scheduleTempItem.week) % 10 > 3 || parseInt(scheduleTempItem.week) % 10 === 0 || [11, 12, 13].includes(parseInt(scheduleTempItem.week))) ? 0 : parseInt(scheduleTempItem.week) % 10
                                                                ]} week`
                                                                : "---"}
                                                        </td>
                                                        <td>
                                                            {scheduleTempItem.workshop.name || "-"}
                                                        </td>
                                                        <td>
                                                            {formatCreatedAt(scheduleTempItem.workshop_slot.date || "---")}
                                                            <br></br>

                                                            {scheduleTempItem.workshop_slot.startTime
                                                                ? new Date(`1970-01-01T${scheduleTempItem.workshop_slot.startTime}`).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
                                                                : "---"}
                                                            -
                                                            {scheduleTempItem.workshop_slot.endTime
                                                                ? new Date(`1970-01-01T${scheduleTempItem.workshop_slot.endTime}`).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
                                                                : "---"}
                                                        </td>
                                                        <td>
                                                            <div className="social-links mt-2">
                                                                <a
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => handleDeleteScheduleTemp(scheduleTempItem.id)}
                                                                >
                                                                    <i className="bi bi-trash3"></i>
                                                                </a>
                                                                &nbsp;
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <h3 style={{ textAlign: "center" }}></h3>
                                    )}
                                </div>

                            </form>
                        </div>

                        <div
                            class="modal-footer"
                            style={{
                                padding: "2%",
                                marginTop: "3%",
                                borderTop: "solid 1px #7e7a7a8c",
                            }}
                        >
                            <button
                                type="button"
                                class="btn btn-danger"
                                data-dismiss="modal"
                                onClick={() => {
                                    onClose();
                                    reloadPage();
                                }}
                            >
                                Close
                            </button>
                            {isAddingnews === true ? (
                                <div>
                                    <ThreeDots color="#000" height={50} width={50} />
                                </div>
                            ) : (
                                <button
                                    id="btnAdd"
                                    type="button"
                                    class="btn btn-primary "
                                    style={{ background: "#000 ", border: "none" }}
                                    onClick={handleAddSchedule}
                                >
                                    Add
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default AddSchedule
