import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import "bootstrap-icons/font/bootstrap-icons.css";
import { TailSpin } from "react-loader-spinner";
import "./Style.css";
import "../../Api/apiUrls";
import { getAdminAboutUs } from "../../Api/apiUrls";
import Add_about from "../../Components/Admin_components/add_about.js";
import Update_aboutUs from "../../Components/Admin_components/update_aboutUs.js";
import Delete_aboutUs from "../../Components/Admin_components/delete_aboutUs.js";
import "../../Api/apiUrls.js";
import { API_URL } from "../../Api/apiUrls";

function About() {
  const [modalAdd_aboutIsOpen, setAdd_aboutModalIsOpen] = useState("");
  const [about, setAbout] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [modalUpdate_aboutUsIsOpen, setUpdate_aboutUsModalIsOpen] =
    useState("");
  const [selectedAboutUsID, setSelectedAboutUsID] = useState("");
  const [selectedAboutUsDescription, setSelectedAboutUsDescription] =
    useState("");
  const [selectedAboutUsImage, setSelectedAboutUsImage] = useState("");
  const [modalDelete_aboutUsIsOpen, setDelete_aboutUsIsOpen] = useState(false);
  const openAdd_about = () => {
    closeAdd_about();
    setAdd_aboutModalIsOpen(true);
  };

  const openUpdate_aboutUs = (aboutUsId, description, photo) => {
    setSelectedAboutUsID(aboutUsId);
    setSelectedAboutUsDescription(description);
    setSelectedAboutUsImage(photo);

    closeUpdate_aboutUs();
    setUpdate_aboutUsModalIsOpen(true);
  };
  const openDelete_aboutUs = (aboutUsId) => {
    setSelectedAboutUsID(aboutUsId);
    closeDelete_aboutUs();
    setDelete_aboutUsIsOpen(true);
  };

  const closeDelete_aboutUs = () => {
    setDelete_aboutUsIsOpen(null);
  };
  const closeUpdate_aboutUs = () => {
    setUpdate_aboutUsModalIsOpen(false);
  };
  const closeAdd_about = () => {
    setAdd_aboutModalIsOpen(false);
  };
  useEffect(() => {
    fetch(getAdminAboutUs)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const about = data.data.AboutUs;
        setAbout(about);
        console.log(about);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  return (
    <div>
      <Header></Header>
      {modalAdd_aboutIsOpen && <Add_about onClose={closeAdd_about} />}
      <main id="main" class="main">
        <div class="pagetitle">
          <h1>About Us</h1>
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="col-md-6"></div>
              <div class="col-md-6" style={{ textAlign: "right" }}>
                <div class="child">
                  <a>
                    {" "}
                    <button
                      id="btnNewUser"
                      type="button"
                      data-toggle="modal"
                      data-target="#modalAdd"
                      class=" btn-round"
                      onClick={openAdd_about}
                    >
                      <i class="bi bi-person-add"></i> Add About Us
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            >
              {/* <TailSpin color="black" width={50} /> */}
            </div>

            <div class="table-responsive">
              {isLoading === true ? (
                <div
                  style={{
                    marginTop: "50px",
                    display: "flex",
                    marginLeft: "50%",
                  }}
                >
                  <TailSpin color="black" width={50} />
                </div>
              ) : about.length > 0 ? (
                <table class="table  table-striped ">
                  <thead>
                    <tr>
                      <th scope="col">Sr.No</th>
                      <th scope="col">Image</th>
                      <th scope="col">Description</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {about.map((about, index) => (
                      <tr key={about.id}>
                        <th scope="row">{index + 1}</th>
                        <td>
                          {about.photo && (
                            <img
                              src={`${API_URL}/${about.photo}` || "-"}
                              style={{ height: "50px", width: "50px" }}
                            />
                          )}
                        </td>
                        <td>{about.about_us_text || "-"}</td>
                        <td>
                          <div className="social-links mt-2">
                            <a
                              onClick={() =>
                                openUpdate_aboutUs(
                                  about.id,
                                  about.about_us_text,
                                  about.photo
                                )
                              }
                            >
                              <i className="bi bi-pencil"></i>
                            </a>
                            &nbsp;
                            <a onClick={() => openDelete_aboutUs(about.id)}>
                              <i className="bi bi-trash3"></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h3 style={{ textAlign: "center" }}> No data available </h3>
              )}
              {modalUpdate_aboutUsIsOpen && (
                <Update_aboutUs
                  aboutUsId={selectedAboutUsID}
                  description={selectedAboutUsDescription}
                  photo={selectedAboutUsImage}
                  onClose={closeUpdate_aboutUs}
                />
              )}

              {modalDelete_aboutUsIsOpen && (
                <Delete_aboutUs
                  aboutUsId={selectedAboutUsID}
                  onClose={closeDelete_aboutUs}
                />
              )}
            </div>
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "10px",
              }}
            >
              {about ? about.length : 0} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default About;
