import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./update_categories.css";
import Select from "react-select";
import "../../Api/apiUrls";
import { UpdateAdminSubCategories, getCategories } from "../../Api/apiUrls";
function Update_subCategories({
  onClose,
  subCategoriesId,
  subCategoriesName,
  subCategoriesDescription,
  categoryName,
  subCategoriesType,
  subCategoriesStatus,
}) {
  console.log(subCategoriesStatus);
  console.log(subCategoriesType);
  console.log(categoryName);
  const [modalUpdate_subCategoriesIsOpen, setUpdate_subCategoriesModalIsOpen] =
    useState("");
  const [categories, setCategories] = useState([]);
  const [newCategoryName, setNewCategoryName] = useState(subCategoriesName);
  const [newCategoryDescription, setNewCategoryDescription] = useState(
    subCategoriesDescription
  );
  const [newCategoryStatus, setNewCategoryStatus] =
    useState(subCategoriesStatus);

  console.log(newCategoryStatus);
  const [newCategoryType, setNewCategoryType] = useState(subCategoriesType);
  const [newCategoryFreeVersion, setNewCategoryFreeVersion] = useState();
  const [isUpdatingSubCategory, setIsUpdatingSubCategory] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState(categoryName);
  const closeUpdate_subCategories = () => {
    setUpdate_subCategoriesModalIsOpen(false);
  };
  const handleFreeVersionChange = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setNewCategoryFreeVersion(newValue);
  };

  const Status = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];
  const Type = [
    { value: "Workshop", label: "Workshop" },
    { value: "Venue", label: "Venue" },
  ];
  const handleTypeChange = (selectedOption) => {
    setNewCategoryType(selectedOption);
  };

  function reloadPage() {
    window.location.reload();
  }

  const handleClick = (e) => {
    if (e.currentTarget === e.target) {
      onClose();
      reloadPage();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  useEffect(() => {
    fetch(`${getCategories}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          if (Array.isArray(data.data.Categories)) {
            setCategories(data.data.Categories);
          } else {
            console.error(
              "Categories data is not an array:",
              data.data.Categories
            );
          }
        } else {
          console.error("Error fetching categories:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  const newCategoryOptions = categories.map((category) => ({
    value: category.id,
    label: category.name,
  }));

  const handleCategoryChange = (selectedOptions) => {
    console.log(selectedOptions);
    setSelectedCategories(selectedOptions);
  };
  const handleStatusChange = (selectedOption) => {
    setNewCategoryStatus(selectedOption);
  };
  function reloadPage() {
    window.location.reload();
  }

  const handleUpdate = () => {
    setIsUpdatingSubCategory(true);
    console.log(newCategoryType.label);
    console.log(newCategoryStatus.label);
    console.log(selectedCategories.value);

    const formData = new FormData();
    formData.append("SubCategories_id", subCategoriesId);
    formData.append("name", newCategoryName);
    formData.append("description", newCategoryDescription);
    formData.append("category", selectedCategories.value);
    formData.append("status", newCategoryStatus.label);
    formData.append("type", newCategoryType.label);

    // formData.append("homepage", newCategoryFreeVersion);
    // console.log(categoryHomepage);
    fetch(UpdateAdminSubCategories, {
      method: "PUT",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert("Updated");
          setIsUpdatingSubCategory(false);

          window.location.reload();
          onClose();
        } else {
          console.error("Error updating category:", data.message);
          setIsUpdatingSubCategory(false);
        }
      })
      .catch((error) => {
        console.error("Error updating category:", error);
        setIsUpdatingSubCategory(false);
      });
  };

  return (
    <div>
      {modalUpdate_subCategoriesIsOpen && (
        <Update_subCategories onClose={closeUpdate_subCategories} />
      )}
      <div className="popup-background">
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "80%",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Update Sub Categories</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "scroll", padding: "2%" }}
            >
              <form>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Name<font color="red"></font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    value={newCategoryName}
                    onChange={(e) => setNewCategoryName(e.target.value)}
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Description
                  </label>
                  <textarea
                    type="text"
                    id="selectedImg"
                    class="form-control"
                    value={newCategoryDescription}
                    onChange={(e) => setNewCategoryDescription(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Category <font color="red"></font>
                  </label>
                  <Select
                    options={newCategoryOptions}
                    value={selectedCategories}
                    onChange={handleCategoryChange}
                    styles={{
                      placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "black",
                      }),
                      clearIndicator: (baseStyles) => ({
                        ...baseStyles,
                        color: "red",
                      }),
                      dropdownIndicator: (baseStyles) => ({
                        ...baseStyles,
                        color: "black",
                      }),
                      control: (baseStyles) => ({
                        ...baseStyles,
                        borderColor: "darkblue",
                      }),
                      multiValueRemove: (baseStyles, state) => ({
                        ...baseStyles,
                        color: state.isFocused ? "red" : "gray",
                        backgroundColor: state.isFocused
                          ? "black"
                          : "lightgreen",
                      }),
                    }}
                  ></Select>
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Category Type
                  </label>
                  <Select
                    onChange={handleTypeChange}
                    options={Type}
                    value={newCategoryType}
                  ></Select>
                </div>
                {/* <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Category Type
                  </label>
                  <Select onChange={handleTypeChange} options={Type}></Select>
                </div> */}
                {/* <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Category <font color="red"></font>
                  </label>
                  <Select options={Status}></Select>
                </div> */}
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Status <font color="red"></font>
                  </label>
                  <Select
                    options={Status}
                    value={newCategoryStatus}
                    onChange={handleStatusChange}
                  ></Select>
                </div>
                {/* <div class="form-group" style={{ display: "block" }}>
                  <input
                    style={{ marginRight: "8px" }}
                    type="checkbox"
                    id="checkbox1"
                    name="freeOption1"
                    // checked={freeVersion}
                    onChange={handleFreeVersionChange}
                  ></input>
                  <label class="small mb-1" for="inputFullName">
                    Show on Home Page
                  </label>
                  <br></br>
                </div> */}
              </form>
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
              >
                Close
              </button>

              {/* <div>
                <ThreeDots color="#000" height={50} width={50} />
              </div> */}

              <button
                id="btnAdd"
                type="button"
                class="btn btn-primary "
                style={{ background: "#000", border: "none" }}
                onClick={handleUpdate}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Update_subCategories;
