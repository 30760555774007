import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import { TailSpin } from "react-loader-spinner";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import Add_news from "../../Components/Admin_components/add_news.js";
import Update_news from "../../Components/Admin_components/update_news.js";
import Delete_news from "../../Components/Admin_components/delete_news.js";
import NewsComments from "../../Components/Admin_components/newsComment.js";
import NewsSave from "../../Components/Admin_components/newsSave.js";
import "../../Api/apiUrls";
import { API_URL, getAdminNews, newsExcel } from "../../Api/apiUrls";

function News() {
  const [modalAdd_newsIsOpen, setAdd_newsModalIsOpen] = useState("");
  const [modalUpdate_newsIsOpen, setUpdate_newsModalIsOpen] = useState(false);
  const [modalDelete_newsIsOpen, setDelete_newsIsOpen] = useState(false);
  const [modalNewsCommentsIsOpen, setNewsCommentsIsOpen] = useState(false);
  const [modalNewsSaveIsOpen, setNewsSaveIsOpen] = useState(false);
  const [selectednewsName, setSelectedNewsName] = useState(null);
  const [selectednewsImage, setSelectedNewsImage] = useState(null);
  const [selectednewsDescription, setSelectedNewsDescription] = useState(null);
  const [selectednewsSummary, setSelectedNewsSummary] = useState(null);
  const [selectedAuthor, setSelectedAuthor] = useState(null);
  const [selectedNewsStatus, setSelectedNewsStatus] = useState(null);
  const [selectedNewsKeyword, setSelectedNewsKeyword] = useState(null);
  const [news, setNews] = useState(false);
  const [selectedNewsId, setSelectedNewsId] = useState(null);
  const [status, setStatus] = useState("All");
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [id, setId] = useState("");
  const [statusDetails, setStatusDetails] = useState(null);
  const [statusSingle, setStatusSingle] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [filteredItems, setFilteredItems] = useState(null);
  const [newsSelectedAuthor, setNewSelectedAuthor] = useState(null);
  const drop = [
    { value: "All", label: "All" },
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption.value);
  };

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredBlogs = Array.isArray(news)
    ? news.filter((news) => {
      const isStatus =
        !selectedStatus ||
        selectedStatus === "All" ||
        news.status === selectedStatus;
      const values = Object.values(news).map((value) =>
        typeof value === "string" ? value.toLowerCase() : ""
      );
      const isSearchMatch = values.some((value) =>
        value.includes(searchQuery.toLowerCase())
      );
      return isStatus && isSearchMatch;
    })
    : [];
  const openAdd_news = () => {
    closeAdd_news();
    setAdd_newsModalIsOpen(true);
  };

  const closeAdd_news = () => {
    setAdd_newsModalIsOpen(false);
  };
  const openUpdate_news = (
    newsId,
    newsName,
    newsImage,
    newsDescription,
    newsSummary,
    newsKeyword,
    newsAuthor,
    newsStatus
  ) => {
    setSelectedNewsId(newsId);
    setSelectedNewsName(newsName);
    setSelectedNewsImage(newsImage);
    setSelectedNewsDescription(newsDescription);
    setSelectedNewsSummary(newsSummary);
    setSelectedNewsKeyword(newsKeyword);
    setNewSelectedAuthor(newsAuthor);
    setSelectedNewsStatus(newsStatus);

    closeUpdate_news();
    setUpdate_newsModalIsOpen(true);
  };

  const closeUpdate_news = () => {
    setUpdate_newsModalIsOpen(false);
  };
  const openDelete_news = (newsId) => {
    closeDelete_news();
    setSelectedNewsId(newsId);
    setDelete_newsIsOpen(true);
  };

  const closeDelete_news = () => {
    setDelete_newsIsOpen(null);
  };
  const openNewsComments = (id) => {
    closeNewsComments();
    setId(id);
    setNewsCommentsIsOpen(true);
  };

  const closeNewsComments = () => {
    setNewsCommentsIsOpen(null);
  };

  const openNewsSave = (id) => {
    closeNewsSave();
    setId(id);
    setNewsSaveIsOpen(true);
  };

  const closeNewsSave = () => {
    setNewsSaveIsOpen(null);
  };

  useEffect(() => {
    fetch(getAdminNews)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const news = data.data.BlogsAndNews;
        setNews(news);
        console.log(news);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  // useEffect(() => {
  //   const filtered = news.filter((item) =>
  //     item.title.toLowerCase().includes(searchQuery.toLowerCase())
  //   );
  //   console.log("filtered");
  //   console.log(filtered);
  //   setNews(filtered);
  // }, [searchQuery, news]);
  // const formatCreatedAt = (createdAt) => {
  //   const createdAtDate = new Date(createdAt);
  //   return createdAtDate.toLocaleDateString("en-GB", {
  //     day: "numeric",
  //     month: "short",
  //     year: "numeric",
  //   });
  // };
  const formatCreatedAt = (createdAt) => {
    const createdAtDate = new Date(createdAt);

    // Format the date part
    const datePart = createdAtDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });

    // Format the time part
    const timePart = createdAtDate.toLocaleTimeString("en-GB", {
      hour: "numeric",
      minute: "numeric",

      hour12: true,
    });

    return `${datePart} ${timePart}`;
  };
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  function handleDownloadClick() {
    window.open(newsExcel, "_blank");
  }
  return (
    <div>
      <Header></Header>

      {modalAdd_newsIsOpen && <Add_news onClose={closeAdd_news} />}
      {modalUpdate_newsIsOpen && <Update_news onClose={closeUpdate_news} />}
      {modalNewsCommentsIsOpen && (
        <NewsComments onClose={closeNewsComments} id={id} />
      )}
      {modalNewsSaveIsOpen && <NewsSave onClose={closeNewsSave} id={id} />}

      <main id="main" class="main">
        <div class="pagetitle">
          <h1>News</h1>
          {/* <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/categories">Home</a>
              </li>
              <li class="breadcrumb-item active">News</li>
            </ol>
          </nav> */}
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="col-md-6">
                <div class="child">
                  <input
                    onChange={handleSearchChange}
                    value={searchQuery}
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                    }}
                    placeholder="Search..."
                  />
                </div>
                <div className="child ml-2">
                  <Select
                    options={drop}
                    placeholder="Select Status"
                    onChange={handleStatusChange}
                  ></Select>
                </div>
              </div>

              <div class="col-md-6" style={{ textAlign: "right" }}>
                <div class="child">
                  <a>
                    {" "}
                    <button
                      id="btnNewUser"
                      type="button"
                      data-toggle="modal"
                      data-target="#modalAdd"
                      class=" btn-round"
                      onClick={openAdd_news}
                    >
                      <i class="bi bi-person-add"></i> Add News
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="child ml-2 col-md-12"
              style={{ cursor: "pointer", textAlign: "end" }}
              onClick={handleDownloadClick}
            >
              Download
              <i
                style={{ fontSize: "20px", marginLeft: "10px" }}
                class="bi bi-download"
              ></i>
            </div>
            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            >
              {/* <TailSpin color="black" width={50} /> */}
            </div>
            <div class="table-responsive">
              {isLoading === true ? (
                <div
                  style={{
                    marginTop: "50px",
                    display: "flex",
                    marginLeft: "50%",
                  }}
                >
                  <TailSpin color="black" width={50} />
                </div>
              ) : news.length > 0 ? (
                <table class="table table-striped " style={{ padding: "0rem" }}>
                  <thead>
                    <tr>
                      <th scope="col">Sr.no</th>
                      <th scope="col">Image</th>
                      <th scope="col">Name</th>
                      {/* <th scope="col">NewsID</th> */}
                      <th scope="col">Owner</th>
                      {/* <th scope="col">Comments</th> */}
                      <th scope="col">Visit</th>
                      <th scope="col">Share</th>
                      <th scope="col">PackageType</th>
                      {/* <th scope="col"> Status Package Type</th> */}
                      {/* <th scope="col">Author</th> */}
                      <th scope="col">Keywords</th>
                      <th scope="col">Created On</th>
                      <th scope="col">Publish On</th>
                      <th scope="col">Expiring On</th>
                      <th scope="col">Status</th>
                      <th scope="col">Save</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredBlogs.map((news, index) => (
                      <tr key={news.id}>
                        <th scope="row">{index + 1}</th>
                        <td>
                          {news.photo && (
                            <img
                              src={`${API_URL}/${news.photo}` || "-"}
                              style={{ height: "50px", width: "50px" }}
                            />
                          )}
                        </td>
                        <td>{news.title || "---"}</td>
                        {/* <td>{news.id || "---"}</td> */}
                        <td>
                          {news.instructor.name || "---"}
                          <br />
                          {news.instructor.mobileNumber || "---"}
                          <br />
                          {news.instructor.email || "---"}
                          <br />
                          {/* {news.type || "---"} */}
                          <br />
                        </td>

                        {/* <td>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => openNewsComments(news.id)}
                          >
                            {news.comments}
                          </a>
                        </td> */}
                        <td>{news.visit || "---"}</td>
                        <td>{news.share || "---"}</td>
                        <td>{news.package?.duration || "---"}</td>
                        {/* <td>{news.package_type_status || "---"}</td> */}
                        {/* <td>{news.news_author.name || "-"}</td> */}
                        <td>{news?.keywords || "-"}</td>
                        <td>{formatCreatedAt(news.created_on)}</td>
                        <td>{formatCreatedAt(news.created_at)}</td>
                        <td>{formatCreatedAt(news.expiring_on)}</td>
                        <td>{news.status || "-"}</td>
                        {/* <td>
                          <b>{news.save_count || "-"}</b>
                        </td> */}
                        <td>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => openNewsSave(news.id)}
                          >
                            {news.save_count}
                          </a>
                        </td>
                        <td>
                          <div className="social-links mt-2">
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                openUpdate_news(
                                  news.id,
                                  news.title,
                                  news.photo,
                                  news.discription,
                                  news.shortSummery,
                                  news.keywords,
                                  {
                                    value: news.news_author.id,
                                    label: news.news_author.name,
                                  },

                                  { value: news.id, label: news.status }
                                )
                              }
                            >
                              <i class="bi bi-pencil"></i>
                            </a>
                            &nbsp;&nbsp;
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() => openDelete_news(news.id)}
                            >
                              <i class="bi bi-trash3"></i>
                            </a>
                            &nbsp;
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h3 style={{ textAlign: "center" }}> No data available </h3>
              )}

              {modalUpdate_newsIsOpen && (
                <Update_news
                  newsId={selectedNewsId}
                  newsName={selectednewsName}
                  newsImage={selectednewsImage}
                  newsDescription={selectednewsDescription}
                  newsSummary={selectednewsSummary}
                  newsKeyword={selectedNewsKeyword}
                  newsAuthor={newsSelectedAuthor}
                  newsStatus={selectedNewsStatus}
                  onClose={closeUpdate_news}
                />
              )}

              {modalDelete_newsIsOpen && (
                <Delete_news
                  newsId={selectedNewsId}
                  onClose={closeDelete_news}
                />
              )}
            </div>
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "10px",
              }}
            >
              {filteredBlogs.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default News;
