import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "../../Api/apiUrls.js";
import axios from 'axios';
import { API_URL } from "../../Api/apiUrls";


function AdminUpdateInvoice({ invoiceId, onClose }) {
    console.log(invoiceId);
    const [modalUpdateInvoiceIsOpen, setUpdateInvoiceModalIsOpen] =
        useState("");
    const closeUpdateInvoice = () => {
        setUpdateInvoiceModalIsOpen(false);
    };

    const [invoiceData, setInvoiceData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    function reloadPage() {
        window.location.reload();
    }

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const formatTime = (timeString) => {
        const [hours, minutes] = timeString.split(':');
        const date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    };
    useEffect(() => {
        // Fetch the data from the API
        const fetchData = async () => {
            try {
                const response = await axios.get(`${API_URL}/upload_invoice/get/?invoice_id=${invoiceId}`);
                if (response.data.success) {
                    setInvoiceData(response.data.data);
                    // const date = workshop_slot?.date;
                    // const startTime = workshop_slot?.startTime;
                    // const endTime = workshop_slot?.endTime;
                    setIsLoading(false);
                }
            } catch (error) {

                console.error('Error fetching invoice data:', error);
            }
        };

        fetchData();
    }, []);

    const updateReviewStatus = async (status) => {
        try {
            const response = await fetch(`${API_URL}/upload_invoice/update/`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    status: status,
                    invoice_id: invoiceId,
                }),
            });

            if (response.ok) {
                onClose();
                reloadPage();
            } else {
                // Handle API error
                console.error("Failed to update review status");
            }
        } catch (error) {
            console.error("Error occurred while updating review status", error);
        }
    };
    return (
        <div>
            {modalUpdateInvoiceIsOpen && (
                <AdminUpdateInvoice onClose={closeUpdateInvoice} />
            )}
            <div className="popup-background">
                <div className="popup">
                    <div
                        class="modal-content"
                        style={{
                            width: "100%",
                            height: "80%",
                            padding: "2%",
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                        }}
                    >
                        <div
                            class="modal-header"
                            style={{
                                width: "100%",
                                marginBottom: "3%",
                                borderBottom: "solid 1px #7e7a7a8c",
                            }}
                        >
                            <h5
                                class="modal-title"
                                id="modalAddTitle"
                                style={{ marginBottom: "2%" }}
                            >
                                <b>Upload Invoice</b>
                            </h5>

                            <button
                                type="button"
                                onClick={() => {
                                    onClose();
                                    // reloadPage();
                                }}
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div
                            class="modal-body"
                            style={{ overflowY: "scroll", padding: "2%" }}
                        >

                            <table class="table">
                                <tbody id="tableBody">
                                    {invoiceData.map((invoiceData) => (

                                        <tr>
                                            <p>
                                                <strong>Title:</strong> {invoiceData.workshop?.name}
                                            </p>
                                            <p>
                                                <strong>Instructor Details:</strong> <br />
                                                {invoiceData.user?.name}<br />
                                                {invoiceData.user?.email}<br />
                                                {invoiceData.user?.mobileNumber}
                                            </p>
                                            <p>
                                                <strong>Slot with Appointment date:</strong> <br />
                                                {formatDate(invoiceData.workshop_slot?.date)}<br />
                                                {formatTime(invoiceData.workshop_slot?.startTime)} - {formatTime(invoiceData.workshop_slot?.endTime)}

                                            </p>

                                            <p>
                                                <strong>Status:</strong>{" "}
                                                {invoiceData.status === 'Accepted'
                                                    ? "Accepted"
                                                    : invoiceData.status === 'Pending'
                                                        ? "Pending"
                                                        : invoiceData.status === 'Rejected'
                                                            ? "Rejected"
                                                            : "-"}
                                            </p>
                                        </tr>

                                    ))}
                                </tbody>
                            </table>


                        </div>

                        <div
                            class="modal-footer"
                            style={{
                                padding: "2%",
                                marginTop: "3%",
                                borderTop: "solid 1px #7e7a7a8c",
                            }}
                        >
                            <button
                                type="button"
                                class="btn btn-danger"
                                data-dismiss="modal"
                                onClick={() => updateReviewStatus('Rejected')}
                            >
                                Reject
                            </button>

                            <button
                                id="btnAdd"
                                type="button"
                                class="btn btn-primary "
                                style={{ background: "#000 ", border: "none" }}
                                onClick={() => updateReviewStatus('Accepted')}
                            >
                                Approve
                            </button>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default AdminUpdateInvoice
