import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import "bootstrap-icons/font/bootstrap-icons.css";
import "../Admin/Style.css";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import Add_subcategories from "../../Components/Admin_components/add_subcategories.js";
import Update_subCategories from "../../Components/Admin_components/update_subCategories.js";
import Delete_subcategories from "../../Components/Admin_components/delete_subCategories.js";
import Workshoplist from "../../Components/Admin_components/workshopDetails.js";
import "../../Api/apiUrls";
import {
  UpdateAdminToggleSubCategory,
  getAdminSubCategories,
} from "../../Api/apiUrls";

function Sub_Categories() {
  const [modalAdd_subcategoriesIsOpen, setAdd_subcategoriesModalIsOpen] =
    useState("");
  const [modalUpdate_subCategoriesIsOpen, setUpdate_subCategoriesModalIsOpen] =
    useState(false);
  const [modalDelete_subcategoriesIsOpen, setDelete_subcategoriesIsOpen] =
    useState(false);
  const [toggleStates, setToggleStates] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  // const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
  const [selectedCategoryName, setSelectedCategoryName] = useState(null);
  const [selectedCategoryDescription, setSelectedCategoryDescription] =
    useState(null);
  const [selectedCategoryStatus, setSelectedCategoryStatus] = useState(null);
  const [selectedCategoryType, setSelectedCategoryType] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoryHomePage, setSelectedCategoryHomePage] =
    useState(null);
  const [selectedCategoryFreeVersion, setSelectedCategoryFreeVersion] =
    useState(null);
  const [subCategories, setSubCategories] = useState([]);

  const Status = [
    { value: "All", label: "All" },
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];
  const Type = [
    { value: "All", label: "All" },
    { value: "Workshop", label: "Workshop" },
    { value: "Venue", label: "Venue" },
  ];

  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedType, setSelectedType] = useState(null);

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption.value);
  };

  const handleTypeChange = (selectedOption) => {
    setSelectedType(selectedOption.value);
  };

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };
  useEffect(() => {
    const filteredSubCategories = subCategories.filter((subcategory) => {
      const isStatus =
        !selectedStatus ||
        selectedStatus === "All" ||
        subcategory.status === selectedStatus;
      const isType =
        !selectedType ||
        selectedType === "All" ||
        subcategory.type === selectedType;
  
      // Combine all searchable fields into a single array
      const searchableValues = [
        subcategory.name,
        subcategory.description,
        subcategory.type,
        subcategory.category.name,  // Include category name in search
        subcategory.status,
      ];
  
      const isSearchMatch = searchableValues.some((value) =>
        typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
      );
  
      return isStatus && isType && isSearchMatch;
    });
  
    setFilteredSubCategories(filteredSubCategories);
  }, [selectedType, selectedStatus, subCategories, searchQuery]);
  

  const openAdd_subcategories = () => {
    closeAdd_subcategories();
    setAdd_subcategoriesModalIsOpen(true);
  };

  const closeAdd_subcategories = () => {
    setAdd_subcategoriesModalIsOpen(false);
  };
  const openUpdate_subCategories = (
    subCategoriesId,
    subCategoriesName,
    subCategoriesDescription,
    categoryName,
    subCategoriesType,
    subCategoriesStatus
  ) => {
    setSelectedSubCategoryId(subCategoriesId);
    setSelectedCategoryName(subCategoriesName);
    setSelectedCategoryDescription(subCategoriesDescription);
    setSelectedCategory(categoryName);
    setSelectedCategoryType(subCategoriesType);
    setSelectedCategoryStatus(subCategoriesStatus);
    closeUpdate_subCategories();
    setUpdate_subCategoriesModalIsOpen(true);
  };

  const closeUpdate_subCategories = () => {
    setUpdate_subCategoriesModalIsOpen(false);
  };
  const openDelete_subcategories = (subCategoryId) => {
    setSelectedSubCategoryId(subCategoryId);
    closeDelete_subcategories();
    setDelete_subcategoriesIsOpen(true);
  };

  const closeDelete_subcategories = () => {
    setDelete_subcategoriesIsOpen(null);
  };
  const toggleSwitch = async (SubCategories_id) => {
    const newToggleState = toggleStates[SubCategories_id] === 1 ? 0 : 1;
    console.log(newToggleState);

    // Update the local state
    setToggleStates((prevState) => ({
      ...prevState,
      [SubCategories_id]: newToggleState,
    }));
    try {
      const response = await fetch(UpdateAdminToggleSubCategory, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          homepage: newToggleState === 1 ? "1" : "0",
          SubCategories_id: SubCategories_id,
        }),
      });

      const data = await response.json();
      console.log(data); // Log the server response
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch(
  //         "http://3.110.1.182:8000/sub_categories/get-sub_categories/"
  //       );
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       const data = await response.json();
  //       setSubCategories(data.data.SubCategories);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData();
  // }, []);
  useEffect(() => {
    fetch(getAdminSubCategories)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const subcategory = data.data.SubCategories;
        console.log(subcategory);
        setSubCategories(subcategory);
        const initialToggleStates = subcategory.reduce((acc, subcategory) => {
          acc[subcategory.id] = subcategory.homepage === "1" ? 1 : 0;
          return acc;
        }, {});

        setToggleStates(initialToggleStates);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);



  const [id, setId] = useState("");
  const [type, seType] = useState("subcategory");
  const [modalWorkshoplistIsOpen, setWorkshoplistModalIsOpen] =
  useState("");

const openWorkshoplist = (id , type) => {
  setId(id);
  seType(type);
  setWorkshoplistModalIsOpen(true);
};

const closeWorkshoplist = () => {
  setWorkshoplistModalIsOpen(false);
};


  return (
    <div>
      <Header></Header>

      {modalAdd_subcategoriesIsOpen && (
        <Add_subcategories onClose={closeAdd_subcategories} />
      )}
      {modalUpdate_subCategoriesIsOpen && (
        <Update_subCategories onClose={closeUpdate_subCategories} />
      )}
      {modalDelete_subcategoriesIsOpen && (
        <Delete_subcategories
          subCategoryId={selectedSubCategoryId}
          onClose={closeDelete_subcategories}
        />
      )}

{modalWorkshoplistIsOpen && (
        <Workshoplist onClose={closeWorkshoplist} id={id} type={type} />
      )}
      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Sub-Category</h1>
          {/* <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/categories">Home</a>
              </li>
              <li class="breadcrumb-item active">Sub-Category</li>
            </ol>
          </nav> */}
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div style={{ display: "flex" }}>
                <div
                  class=" col-md-2"
                  style={{ padding: "5px", maxWidth: "100%" }}
                >
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                      width: "100%",
                    }}
                    onChange={handleSearchInputChange}
                    value={searchQuery}
                    placeholder="Search..."
                  />
                </div>
                <div
                  class="col-md-2"
                  style={{ padding: "5px", maxWidth: "100%" }}
                >
                  <Select
                    options={Status}
                    placeholder="Select Status"
                    onChange={handleStatusChange}
                  ></Select>
                </div>
                <div
                  class="col-md-2"
                  style={{ padding: "5px", maxWidth: "100%" }}
                >
                  {/* <Select
                    options={Type}
                    placeholder="Select Type"
                    onChange={handleTypeChange}
                  ></Select> */}
                </div>
                <div
                  class="col-md-2"
                  style={{
                    padding: "5px",
                    maxWidth: "50%",
                    width: "100%",
                    textAlign: "right",
                  }}
                >
                  <a>
                    {" "}
                    <button
                      id="btnNewUser"
                      type="button"
                      data-toggle="modal"
                      data-target="#modalAdd"
                      class=" btn-round"
                      onClick={openAdd_subcategories}
                    >
                      <i class="bi bi-person-add"></i> Add Sub-Category
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            >
              {/* <TailSpin color="black" width={50} /> */}
            </div>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Sr.No</th>
                  <th scope="col">Name</th>
                  <th scope="col">Description</th>
                  <th scope="col">Category Type</th>
                  <th scope="col">Category</th>
                  <th scope="col">Listing Count</th>
                  <th scope="col">Status</th>
                  <th scope="col">Show on Home Page</th>
                  
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredSubCategories.map((subcategory, index) => (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>{subcategory.name}</td>
                    <td>{subcategory.description}</td>
                    <td>{subcategory.type}</td> 
                    <td>{subcategory.category.name}</td>
                    <td>
                    <a
                            style={{ cursor: "pointer" }}
                            onClick={() => openWorkshoplist(subcategory.id , "subcategory")}
                          >
                      {subcategory.workshop_count}
                      </a>
                      </td>
                    <td>{subcategory.status}</td>
                    <td>
                      <Toggle
                        checked={toggleStates[subcategory.id] === 1}
                        onChange={() => toggleSwitch(subcategory.id)}
                      />
                    </td>

                    <td>
                      <div className="social-links mt-2">
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            openUpdate_subCategories(
                              subcategory.id,
                              subcategory.name,
                              subcategory.description,
                              {
                                value: subcategory.category.id,
                                label: subcategory.category.name,
                              },
                              {
                                value: subcategory.id,
                                label: subcategory.type,
                              },
                              {
                                value: subcategory.id,
                                label: subcategory.status,
                              }
                            )
                          }
                        >
                          <i className="bi bi-pencil"></i>
                        </a>
                        &nbsp;&nbsp;
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            openDelete_subcategories(subcategory.id)
                          }
                        >
                          <i className="bi bi-trash3"></i>
                        </a>
                        &nbsp;
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {modalUpdate_subCategoriesIsOpen && (
              <Update_subCategories
                subCategoriesId={selectedSubCategoryId}
                subCategoriesName={selectedCategoryName}
                subCategoriesDescription={selectedCategoryDescription}
                categoryName={selectedCategory}
                subCategoriesType={selectedCategoryType}
                subCategoriesStatus={selectedCategoryStatus}
                onClose={closeUpdate_subCategories}
              />
            )}
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "10px",
              }}
            >
              {filteredSubCategories.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default Sub_Categories;
