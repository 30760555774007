import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./update_appointment.css";
import "../../Api/apiUrls.js";
import { updateReview } from "../../Api/apiUrls";

function Update_Customer_Rating({ onClose, reviewId, title, review, rating }) {
  const [
    modalUpdate_Customer_RatingIsOpen,
    setUpdate_Customer_RatingModalIsOpen,
  ] = useState("");
  const [newTittle, setNewTittle] = useState(title);
  const [newReview, setNewReview] = useState(review);
  const [newRating, setNewRating] = useState(rating);

  const closeUpdate_Customer_Rating = () => {
    setUpdate_Customer_RatingModalIsOpen(false);
  };

  const maxRating = 5; // Maximum rating (number of stars)
  // const [rating, setRating] = useState(0);

  const handleRatingChange = (newRating) => {
    setNewRating(newRating);
  };
  function reloadPage() {
    window.location.reload();
  }

  const handleClick = (e) => {
    if (e.currentTarget === e.target) {
      onClose();
      reloadPage();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);
  const renderStars = () => {
    const starIcons = [];

    for (let i = 1; i <= maxRating; i++) {
      // Determine whether to render a filled or empty star based on the newRating
      const isFilled = i <= newRating;

      // Add the appropriate star icon (you can customize the star icons as needed)
      starIcons.push(
        <i
          key={i}
          className={`bi bi-star${isFilled ? "-fill" : ""}`}
          style={{ color: isFilled ? "gold" : "gray", cursor: "pointer" }}
          onClick={() => handleRatingChange(i)}
        ></i>
      );
    }

    return starIcons;
  };

  const [isUpdatingRating, setIsUpdatingRating] = useState(false);
  function reloadPage() {
    window.location.reload();
  }
  const handleUpdate = () => {
    setIsUpdatingRating(true);
    const formData = new FormData();
    formData.append("Review_id", reviewId);

    formData.append("review", newReview);
    formData.append("rating", newRating);

    fetch(`${updateReview}`, {
      method: "PUT",
      // headers: {
      //   'accept': 'application/json',
      //   'Content-Type': 'application/json',
      // },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert("Updated!!");
          setIsUpdatingRating(false);
          window.location.reload();
          onClose();
        } else {
          console.error("Error updating  Rating:", data.message);
          setIsUpdatingRating(false);
        }
      })
      .catch((error) => {
        console.error("Error updating  Rating:", error);
        setIsUpdatingRating(false);
      });
  };
  return (
    <div>
      {modalUpdate_Customer_RatingIsOpen && (
        <Update_Customer_Rating onClose={closeUpdate_Customer_Rating} />
      )}
      <div className="popup-background" onClick={handleClick}>
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "75%",
              height: "85%",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Update Review Rating</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body" style={{ padding: "2%" }}>
              <form>
                {/* <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Tittle
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    value={newTittle}
                    onChange={(e) => setNewTittle(e.target.value)}
                  />
                </div> */}
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Review
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    value={newReview}
                    onChange={(e) => setNewReview(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Rating
                  </label>
                  <br />
                  {renderStars()}
                </div>
              </form>
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
              >
                Close
              </button>
              {isUpdatingRating === true ? (
                <div>
                  <ThreeDots color="#000" height={50} width={50} />
                </div>
              ) : (
                <button
                  id="btnAdd"
                  type="button"
                  class="btn btn-primary "
                  style={{ background: "#000 ", border: "none" }}
                  onClick={handleUpdate}
                >
                  Update
                </button>
              )}

              {/* <button
                id="btnAdd"
                type="button"
                class="btn btn-primary "
                style={{ background: "#000 ", border: "none" }}
              >
                Update
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Update_Customer_Rating;
