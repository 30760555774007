import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./login.css"
import login from "../Static/img/istockphoto.jpg"
import logo from "../Static/img/ARTIQUITY_LOGO.jpeg"
import pic from "../Static/img/collage2.jpg"
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';

function SignUp() {
    const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [categoryType, setcategoryType] = useState("");

  const handleTypeChange = (selectedOption) => {
    setcategoryType(selectedOption.value);
    console.log(selectedOption.value);
};

const Type = [
    // { value: "Admin", label: "Admin" },
    { value: "Instructor", label: "Instructor" },
    { value: "Venue Owner", label: "Venue Owner" },
    { value: "User", label: "Customer" },
];

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password.length < 8) {
      alert("Password should be at least 8 characters long.");
      return;
    }

    if (password !== confirmPassword) {
      alert("Passwords do not match.");
      return;
    }

    const formData = new FormData();
   
    formData.append("email", username);
    formData.append("password", password);
    formData.append("mobileNumber", mobileNumber);
    formData.append('type_of_user', categoryType);

    // Add other user details to formData as needed

    // Make the API call using fetch or your preferred method
    fetch('http://3.110.1.182:8000/user/add-user/', {
      method: 'POST',
      body: formData,
    })
      .then(response => response.json())
      .then(data => {
        // Handle the response from the server
        console.log(data);
        window.location.href="/"
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  return (
    <div class="col-md-12" style={{ display: 'flex' }} >

    <div class=" col-md-6 body " >
        <div class="wrapper " style={{top:'0px'}}>

       
                <form action="" style={{ padding: '10px',margin:'20px' }} onSubmit={handleSubmit} >
                    <img style={{ width: '85%', marginLeft: '5%' }} src={logo}></img>
                    <h2><b>Create an Account</b></h2>
                    <h5 style={{ textAlign: 'center' }}><b style={{ color: 'grey' }}>Already have an account? </b><a href='/'><b style={{ color: 'black' }}> Sign In? </b></a></h5><br/>
                    <div class="col-md-9" style={{ marginLeft: '13%' }}>
                                    <Select onChange={handleTypeChange} options={Type} ></Select>
                                </div>
                    <div class="input-group">
                        <input type="email" required value={username}
                            onChange={(e) => setUsername(e.target.value)} />
                        <label for="">Email</label>
                    </div>
                    <div class="input-group">
                        <input  required value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} />
                        <label for="">Mobile No.</label>
                    </div>
                    <div class="input-group">
                        <input type="password" required value={password}
                            onChange={(e) => setPassword(e.target.value)} />
                        <label for="">Password</label>
                    </div>
                    <div class="input-group">
                        <input type="confirmpassword" required value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)} />
                        <label for=""> Confirm Password</label>
                    </div>
                    <div style={{marginLeft:'15%' , display:'flex', alignItems:'center'}}>
                        <input type='checkbox'></input><p style={{marginBottom:'0rem',marginLeft:'2%'}}>  I Agree the terms and conditions</p>
                    </div><br/>
                    <button type="submit" class="btnlogin">Submit</button><br/><br/>
                    <a href=''><button class="btnlogin2">Cancel</button></a>
                </form>
                {/* <div style={{ display: 'block' }}>

          <img style={{ width: '320px', height: '400px', boxShadow: '0 0 10px rgba(0, 0, 0, .5)' }} src={login}></img>
      </div> */}
         
        </div>
    </div>
    <div class="col-md-6" >
    <img style={{width:'100%' ,height:'100%', marginTop:'0.5%' ,  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.4)',}} src={pic} ></img>
</div>
</div>
  )
}

export default SignUp
