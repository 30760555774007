import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import "../../Api/apiUrls.js";
import { API_URL, API_URL_NEW, getCustomerSave } from "../../Api/apiUrls";

function Save() {
  const [savedData, setSavedData] = useState([]);
  const customerId = localStorage.getItem("user_id");
  const [BlogsItems, setBlogsItems] = useState([]);
  const [NewsItems, setNewsItems] = useState([]);


  useEffect(() => {
    const hasReloaded = localStorage.getItem('hasReloaded');
    if (!hasReloaded) {
      // alert("hiiiiiiiiiii")
      localStorage.setItem('hasReloaded', 'true');
      window.location.reload();
    }
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(getCustomerSave + customerId);
        const data = await response.json();
        setSavedData(data.data.SavedBlogsAndNews);
        const blogs = data.data.SavedBlogsAndNews.filter(
          (item) => item.type === "Blog"
        );
        setBlogsItems(blogs);
        const news = data.data.SavedBlogsAndNews.filter(
          (item) => item.type === "News"
        );
        setNewsItems(news);
      } catch (error) {
        console.error("Error fetching saved data:", error);
      }
    };

    fetchData();
  }, [customerId]);
  return (
    <div>
      <Header></Header>

      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Save</h1>
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              {/* <div class="col-md-6">
                <div class="child">
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                    }}
                    placeholder="Search..."
                  />
                </div>
              </div> */}
            </div>
            <div style={{ padding: "15px" }}>
              <h5>
                <b>Blogs</b>
              </h5>{" "}
              <br />
              <div
                className="row col-md-12"
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                {BlogsItems.map(({ id, blogsAndNews }) => (
                  <div key={id} className="card-deck col-md-4">
                     <a
                      href={`${API_URL_NEW}singleBlogsDetail.html?id=${blogsAndNews?.id}`}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                    <div className="card" style={{ boxShadow: "none" }}>
                      <img
                        className="card-img-top"
                        src={`${API_URL}/${blogsAndNews?.photo}`}
                        alt="Card cap"
                        style={{
                          width: "95%",
                          borderRadius: "25px",
                          padding: "10px",
                          aspectRatio: "16/10"
                        }}
                      />
                      <div className="card-body">
                        <span style={{ fontSize: "13px" }}>
                          {blogsAndNews?.title}
                        </span>
                        <p
                          style={{
                            marginBottom: "0rem",
                            maxHeight: "5rem",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            fontSize: "11px",
                          }}
                        >
                          {blogsAndNews?.discription}
                        </p>
                        <p style={{ marginBottom: "0rem" }}>
                          <small className="text-muted">
                            <i class="bi bi-save"></i>{" "}
                            {blogsAndNews?.save_count}
                          </small>
                        </p>
                        <p style={{ marginBottom: "0rem" }}>
                          <small className="text-muted">
                            <i class="bi bi-chat"></i> {blogsAndNews?.comments}
                          </small>
                        </p>
                      </div>
                    </div>
                    </a>
                  </div>
                ))}
              </div>
            </div>

            <div style={{ padding: "15px" }}>
              <h5>
                <b>News</b>
              </h5>{" "}
              <br />
              <div
                className="row col-md-12"
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                {NewsItems.map(({ id, blogsAndNews }) => (
                  <div key={id} className="card-deck col-md-4">
                      <a
                      href={`${API_URL_NEW}singleNewsDetail.html?id=${blogsAndNews?.id}`}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                    <div className="card" style={{ boxShadow: "none" }}>
                      <img
                        className="card-img-top"
                        src={`${API_URL}/${blogsAndNews.photo}`}
                        alt="Card cap"
                        style={{
                          width: "95%",
                          borderRadius: "25px",
                          padding: "10px",
                          aspectRatio: "16/10"
                        }}
                      />
                      <div className="card-body">
                        <span style={{ fontSize: "13px" }}>
                          {blogsAndNews.title}
                        </span>
                        <p
                          style={{
                            marginBottom: "0rem",
                            maxHeight: "5rem",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            fontSize: "11px",
                          }}
                        >
                          {blogsAndNews.discription}
                        </p>
                        <p style={{ marginBottom: "0rem" }}>
                          <small className="text-muted">
                            <i class="bi bi-save"></i> {blogsAndNews.save_count}
                          </small>
                        </p>
                        <p style={{ marginBottom: "0rem" }}>
                          <small className="text-muted">
                            <i class="bi bi-chat"></i> {blogsAndNews.comments}
                          </small>
                        </p>
                      </div>
                    </div>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default Save;
