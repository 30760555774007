import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./add_subcategories.css";
import Select from "react-select";
import "../../Api/apiUrls";
import { addAdminSubCategories, getCategories } from "../../Api/apiUrls";

function Add_subcategories({ onClose }) {
  const [modalAdd_subcategoriesIsOpen, setAdd_subcategoriesModalIsOpen] =
    useState("");
  const [isAddingSubcategories, setIsAddingSubcategories] = useState(false);
  const [categories, setCategories] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState([]);
  const [categoryName, setcategoryName] = useState("");
  const [categoryDescription, setcategoryDescription] = useState("");
  const [categoryType, setcategoryType] = useState("");
  const [categoryStatus, setcategoryStatus] = useState("");
  const [isAddingcategories, setIsAddingcategories] = useState(false);
  const [categoryHomepage, setcategoryHomepage] = useState(0);
  const [freeVersion, setFreeVersion] = useState(0);

  const closeAdd_subcategories = () => {
    setAdd_subcategoriesModalIsOpen(false);
  };
  const Type = [
    { value: "Workshop", label: "Workshop" },
    { value: "Venue", label: "Venue" },
  ];
  const Status = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];
  const handleFreeVersionChange = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setFreeVersion(newValue);
  };

  const handleTypeChange = (selectedOption) => {
    setcategoryType(selectedOption.value);
    console.log(selectedOption.value);
  };
  const handleStatusChange = (selectedOption) => {
    setcategoryStatus(selectedOption.value);
  };

  function reloadPage() {
    window.location.reload();
  }
  function reloadPage() {
    window.location.reload();
  }

  const handleClick = (e) => {
    if (e.currentTarget === e.target) {
      onClose();
      reloadPage();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  useEffect(() => {
    fetch(`${getCategories}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          if (Array.isArray(data.data.Categories)) {
            setCategories(data.data.Categories);
            console.log(data.data.Categories);
          } else {
            console.error(
              "Sub Categories data is not an array:",
              data.data.Categories
            );
          }
        } else {
          console.error("Error fetching sub categories:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching sub categories:", error);
      });
  }, []);

  const [selectedCategories, setSelectedCategories] = useState([]);

  const categoryOptions = categories.map((category) => ({
    value: category.id,
    label: category.name,
  }));

  const handleCategoryChange = (selectedOptions) => {
    console.log(selectedOptions);
    setSelectedCategories([selectedOptions]);
    // setaa([selectedOptions.label])

    console.log(selectedOptions);
  };

  useEffect(() => {
    console.log(selectedCategories);
  }, [selectedCategories]);
  async function addCategory(
    categoryName,
    categoryDescription,
    selectedCategory,
    categoryStatus,
    categoryType,
    categoryHomepage,
    freeVersion,
    selectedCategories
  ) {
    try {
      const formData = new FormData();
      formData.append("name", categoryName);
      formData.append("description", categoryDescription);
      formData.append("category", selectedCategories[0].value);
      formData.append("status", selectedCategory);
      formData.append("type", categoryStatus);

      // formData.append("homepage", freeVersion);
      console.log(categoryHomepage);
      const response = await fetch(addAdminSubCategories, {
        method: "POST",
        body: formData,
      });
      const responseData = await response.json();
      if (response.ok) {
        if (responseData.status === 200){
          return true;
        }else{
          return false;
        }
      } else {
        throw new Error("Failed to add category");
      }
    } catch (error) {
      console.error("Error adding sub category:", error);
      return false; // Category addition failed
    }
  }

  const handleAddCategory = async () => {
    if (!categoryName) {
      alert("Please fill in all fields");
      return;
    }

    setIsAddingcategories(true);

    const result = await addCategory(
      categoryName,
      categoryDescription,
      categoryStatus,
      categoryType,
      categoryHomepage.toString(),
      selectedCategory,
      freeVersion,
      selectedCategories
    );

    setIsAddingcategories(false);

    if (result) {
      alert(" Sub Category Added Successfully");
      setSelectedCategory("");
      console.log(categoryName);
      setcategoryName("");
      window.location.reload();
      // setIconFile(null);
      onClose();
    } else {
      alert("Error Adding Category");
    }
  };

  return (
    <div>
      {modalAdd_subcategoriesIsOpen && (
        <Add_subcategories onClose={closeAdd_subcategories} />
      )}
      <div className="popup-background">
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "80%",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
              // overflowY:'scroll'
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Add Sub-categories</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "scroll", padding: "2%" }}
            >
              <form>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Name<font color="red"></font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={categoryName}
                    onChange={(e) => setcategoryName(e.target.value)}
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Description
                  </label>
                  <textarea
                    type="text"
                    id="selectedImg"
                    class="form-control"
                    value={categoryDescription}
                    onChange={(e) => setcategoryDescription(e.target.value)}
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Category <font color="red"></font>
                  </label>

                  <Select
                    options={categoryOptions}
                    //  value={selectedCategories}
                    value={
                      selectedCategories.length > 0
                        ? selectedCategories[0]
                        : null
                    }
                    onChange={handleCategoryChange}
                  ></Select>
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Category Type <font color="red"></font>
                  </label>
                  <Select onChange={handleTypeChange} options={Type}></Select>
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Status <font color="red"></font>
                  </label>
                  <Select
                    options={Status}
                    onChange={handleStatusChange}
                  ></Select>
                </div>
                {/* <div class="form-group" style={{ display: "block" }}>
                  <input
                    style={{ marginRight: "8px" }}
                    type="checkbox"
                    id="checkbox1"
                    name="freeOption1"
                    // checked={freeVersion}
                    onChange={handleFreeVersionChange}
                  ></input>
                  <label class="small mb-1" for="inputFullName">
                    Show on Home Page
                  </label>
                  <br></br>
                </div> */}
              </form>
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
              >
                Close
              </button>
              {isAddingcategories === true ? (
                <div>
                  <ThreeDots color="#000" height={50} width={50} />
                </div>
              ) : (
                <button
                  id="btnAdd"
                  type="button"
                  class="btn btn-primary "
                  style={{ background: "#000 ", border: "none" }}
                  onClick={handleAddCategory}
                >
                  Add
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Add_subcategories;
